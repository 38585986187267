import { format } from 'date-fns';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { InfoPanel } from 'atoms/InfoPanel';
import { InfoPanelItem } from 'atoms/InfoPanel/InfoPanelItem';
import { batteryNotificationIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { BatteryNotificationMessage } from 'generated/graphql';
import { cx, kmToMi } from 'utils';

interface BatteryEventDetailProps {
  event: Partial<BatteryNotificationMessage>;
}

export const BatteryEventDetail = ({
  event: { notificationType, voltage, odometer, time },
}: BatteryEventDetailProps) => {
  const i18nContext = useContext(I18nContext);
  const { distanceInMiles } = useSettings();

  if (!notificationType || !i18nContext) return null;

  const {
    commonTranslations: {
      general: { unavailable_text, time_text },
      uom: { volts_text },
      domain: {
        common: { odometer_text },
        battery: {
          batteryNotification: {
            fields: { voltage_text, notificationType_text },
          },
        },
      },
      enums: { batteryNotificationTypeDescriptionMap },
    },
  } = i18nContext;

  const odometerInKm = odometer ? odometer / 1000 : undefined;
  const odometerConverted = distanceInMiles && odometerInKm ? kmToMi(odometerInKm) : odometerInKm;
  const odometerRounded = odometerConverted ? Math.round(odometerConverted) : undefined;
  const odometerUnit = distanceInMiles ? 'miles' : 'km';

  return (
    <div className="flex flex-col">
      <div className="mx-1 my-2 w-5 text-center text-sm">
        <Icon
          name={batteryNotificationIconMap[notificationType!][0]}
          className={cx('!w-4', batteryNotificationIconMap[notificationType!][1])}
        />
      </div>

      <InfoPanel>
        <InfoPanelItem
          description={notificationType_text}
          value={batteryNotificationTypeDescriptionMap[notificationType]}
        />

        <InfoPanelItem description={time_text} value={time ? format(Date.parse(time), 'PPPPp') : unavailable_text} />

        <InfoPanelItem
          description={voltage_text}
          value={voltage ?? unavailable_text}
          unitOfMeasure={voltage ? volts_text : undefined}
        />

        <InfoPanelItem
          description={odometer_text}
          value={odometerRounded ?? unavailable_text}
          unitOfMeasure={odometerRounded ? odometerUnit : undefined}
        />
      </InfoPanel>
    </div>
  );
};
