import { useContext } from 'react';

import Modal from 'atoms/Modal';
import { I18nContext } from 'common/useT';
import { BatteryEventDetail } from 'components/ActivityHistoryList/EventTypes/Details/BatteryEventDetail';
import { DashboardLightEventDetail } from 'components/ActivityHistoryList/EventTypes/Details/DashboardLightEventDetail';
import DeviceDiagnosticEventDetail from 'components/ActivityHistoryList/EventTypes/Details/DeviceDiagnosticEventDetail';
import { DtcEventDetail } from 'components/ActivityHistoryList/EventTypes/Details/DtcEventDetail';
import { ImpactEventDetail } from 'components/ActivityHistoryList/EventTypes/Details/ImpactEventDetail';
import { TripEventDetail } from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail';
import { BatteryEventRowItem } from 'components/ActivityHistoryList/ListRowItems/BatteryEventRowItem';
import { DashboardLightEventRowItem } from 'components/ActivityHistoryList/ListRowItems/DashboardLightEventRowItem';
import DeviceDiagnosticEventRowItem from 'components/ActivityHistoryList/ListRowItems/DeviceDiagnosticEventRowItem';
import { DtcEventRowItem } from 'components/ActivityHistoryList/ListRowItems/DtcEventRowItem';
import { ImpactEventRowItem } from 'components/ActivityHistoryList/ListRowItems/ImpactEventRowItem';
import { TripEventRowItem } from 'components/ActivityHistoryList/ListRowItems/TripEventRowItem';
import { ItemWrapper } from 'components/ActivityHistoryList/styledComponents';
import { DriverActivityEventItem, DriverActivityHistoryEvent } from 'types/driverActivityHistory';
import { TFunctioni18nWithRequiredDefault } from 'types/react-i18next';
import { VehicleActivityEventItem, VehicleActivityHistoryEvent } from 'types/vehicleActivityHistory';
import { cx } from 'utils';

const getModalData = (
  item: VehicleActivityEventItem | DriverActivityEventItem,
  time: Date,
  tSafe: TFunctioni18nWithRequiredDefault,
): [string, JSX.Element, JSX.Element] => {
  switch (item.__typename) {
    case 'BatteryNotificationMessage':
      return [
        tSafe('components.ActivityHistoryList.ActivityHistoryItem.battery-event-detail', {
          defaultValue: 'Battery Event Detail',
        }),
        <BatteryEventRowItem event={item} time={time} />,
        <BatteryEventDetail event={item} />,
      ];
    case 'DashboardLightEvent':
      return [
        tSafe('components.ActivityHistoryList.ActivityHistoryItem.dashboard-light-event-detail', {
          defaultValue: 'Dashboard Light Event Detail',
        }),
        <DashboardLightEventRowItem event={item} time={time} />,
        <DashboardLightEventDetail event={item} />,
      ];
    case 'DtcEventMessage':
      return [
        tSafe('components.ActivityHistoryList.ActivityHistoryItem.dtc-event-detail', {
          defaultValue: 'DTC Event Detail',
        }),
        <DtcEventRowItem event={item} time={time} />,
        <DtcEventDetail event={item} />,
      ];
    case 'TripRecord':
      return [
        tSafe('components.ActivityHistoryList.ActivityHistoryItem.trip-event-detail', {
          defaultValue: 'Trip Event Detail',
        }),
        <TripEventRowItem event={item} time={time} />,
        <TripEventDetail />,
      ];
    case 'ImpactEventNotificationMessage':
      return [
        tSafe('components.ActivityHistoryList.ActivityHistoryItem.impact-event-detail', {
          defaultValue: 'Impact Event Detail',
        }),
        <ImpactEventRowItem event={item} time={time} />,
        <ImpactEventDetail event={item} />,
      ];
    case 'DeviceDiagnosticMessage':
      return [
        tSafe('components.ActivityHistoryList.ActivityHistoryItem.device-diagnostic-event-detail', {
          defaultValue: 'Device Diagnostic Detail',
        }),
        <DeviceDiagnosticEventRowItem event={item} time={time} />,
        <DeviceDiagnosticEventDetail event={item} />,
      ];
    default:
      throw new Error(`Type ${item.__typename} not recognized!`);
  }
};

interface ActivityHistoryItemProps {
  event: VehicleActivityHistoryEvent | DriverActivityHistoryEvent;
}

const ActivityHistoryItem = ({ event: { item, time } }: ActivityHistoryItemProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;
  const { tSafe } = i18nContext;
  const [title, trigger, body] = getModalData(item, time, tSafe);
  let modalProps = {
    title: title,
    contentClassName: cx(item.__typename === 'TripRecord' && 'w-[95%] h-[95%]', 'text-md overflow-y-hidden'),
    trigger: <ItemWrapper>{trigger}</ItemWrapper>,
    triggerAsChild: true,
  };

  return <Modal {...modalProps}>{body}</Modal>;
};

export default ActivityHistoryItem;
