import { useContext } from 'react';
import { useHistory } from 'react-router';
import { TableInstance } from 'react-table';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';

interface ResetUrlParamsToolbarItemProps {
  table: TableInstance<any>;
  resetFilters?: () => void;
}

const ResetUrlParamsToolbarItem = ({ table, resetFilters }: ResetUrlParamsToolbarItemProps) => {
  const i18nContext = useContext(I18nContext);
  const history = useHistory();

  const resetFiltersAndSorting = () => {
    table.setSortBy([]);
    table.setAllFilters([]);
    resetFilters?.();

    history.push(history.location.pathname);
  };

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <span className="ml-2">
      <Button
        onClick={() => {
          resetFiltersAndSorting();
        }}
        className="text-error text-sm flex-center ui-button-dark"
      >
        <IonIcon name="closeOutline" aria-label="Clear filters and sorting" />

        {tSafe('components.Toolbar.ResetUrlParamsToolbarItem.clear-filters-reset-sorting', {
          defaultValue: 'Clear Filters and Sorting',
        })}
      </Button>
    </span>
  );
};

export default ResetUrlParamsToolbarItem;
