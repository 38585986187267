import { useContext } from 'react';

import { CopyToClipboardButton } from 'atoms/CopyToClipboardButton';
import { driverEventsIconMap } from 'common/driverEvents';
import { I18nContext } from 'common/useT';
import { GetVehicleDetailsDoc } from 'generated/graphql';
import { Result } from 'types';
import { EventWarning } from 'types/mapVehicleDetails';

import { EventListContainer } from './EventListContainer';

interface DriverDetailsProps {
  driverDetails: NonNullable<Result<typeof GetVehicleDetailsDoc>[0]['associations']['driver']>;
}

const DriverDetails = ({
  driverDetails: {
    statistics: { impactEventCounts },
    id,
    user: { name, phoneNumber },
  },
}: DriverDetailsProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      domain: {
        common: { guid_text },
        user: {
          fields: { name_text, phoneNumber_text },
        },
        driverDetails: {
          driver_details_text,
          fields: { events_text },
        },
      },
      general: { unknown_text },
    },
  } = i18nContext;

  const driverEvents: EventWarning[] = impactEventCounts
    .filter((x) => x.count !== 0)
    .map((event) => ({
      description: event.description,
      iconName: driverEventsIconMap[event.type],
      value: event.count,
      warning: true,
    }));

  return (
    <>
      <div className="ml-0.5 text-md">
        <div className="flex mt-2 mb-1">
          <h4 className="text-lg font-bold">{driver_details_text}</h4>

          <CopyToClipboardButton value={id} label={guid_text} className="ml-1 text-sm" />
        </div>

        <div className="grid grid-cols-[auto_1fr] gap-1">
          <div className="font-bold">{name_text}:</div>

          <div className="pl-2">{name}</div>

          <div className="font-bold">{phoneNumber_text}:</div>

          <div className="pl-2">{phoneNumber || unknown_text}</div>
        </div>
      </div>

      <EventListContainer metrics={driverEvents} title={events_text} />
    </>
  );
};

export default DriverDetails;
