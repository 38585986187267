import { useContext } from 'react';
import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { driverListState } from 'components/Driver/state';
import { cx } from 'utils';

const DriverListEditModeToolbarItem = () => {
  const i18nContext = useContext(I18nContext);
  const [state, setDriverListState] = useRecoilState(driverListState);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { edit_mode_text },
    },
  } = i18nContext;

  return (
    <span className="flex-center">
      <Button
        onClick={() => setDriverListState({ ...state, isInEditMode: !state.isInEditMode })}
        className={cx('text-lg flex-center ui-button-dark', !state.isInEditMode && 'opacity-40')}
      >
        <IonIcon name="settingsOutline" aria-label={edit_mode_text} />
      </Button>
    </span>
  );
};

export default DriverListEditModeToolbarItem;
