import { useContext } from 'react';

import CountWithDetails from 'atoms/react-table/CountWithDetails';
import { I18nContext } from 'common/useT';
import { GetVehicleListQuery } from 'generated/graphql';

interface VehicleListDtcProps {
  dtcs: GetVehicleListQuery['aggregatedVehicleDetails']['data'][number]['activeDtcs'];
  dtcType: GetVehicleListQuery['aggregatedVehicleDetails']['data'][number]['activeDtcs'][0]['dtcType'];
}

const VehicleListDtc = ({ dtcs, dtcType }: VehicleListDtcProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      domain: {
        vehicleDetails: {
          fields: { activeDtcs_text },
        },
      },
    },
  } = i18nContext;

  const filteredDtcs = dtcs.filter((x) => x.dtcType.toUpperCase() === dtcType);

  return (
    <CountWithDetails label={activeDtcs_text} count={filteredDtcs.length}>
      <div className="flex flex-col gap-2">
        {filteredDtcs.map((dtc) => (
          <div className="flex gap-1 items-center" key={dtc.dtcType}>
            <span className="px-1 py-0.5 text-white text-md bg-error rounded-4">{dtc.code}</span>

            <span className="text-sm">{dtc.description}</span>
          </div>
        ))}
      </div>
    </CountWithDetails>
  );
};

export default VehicleListDtc;
