import { WarningLevel } from 'components/Vehicle/Detail/Levels/LevelRow';
import {
  BatteryNotificationType,
  BatterySohState,
  CurrencyUnit,
  DashboardLightMessageType,
  ReportPeriod,
  ReportSchedule,
  ReportType,
  WeeklyReport,
  DeviceDiagnosticMessageType,
  TripStatisticType,
  DeviceConnectionStatus,
  DtcStatus,
  Period,
  DtcEventType,
  UserLocale,
  BatteryState,
  BulkUploadStatus,
  DriverEventType,
  CrashSeverity,
  ConfidenceLevel,
  ConsentStatus,
  DashboardLightStatus,
  DeviceType,
  DistanceUnit,
  FleetMetaFieldDataType,
  FleetNotificationRecipientGroup,
  FuelType,
  GeofenceBreachType,
  GeofenceColor,
  LeaderboardType,
  NotificationType,
  OdoSource,
  Topic,
  TripEcoScoreType,
  TripEventType,
  TripSortType,
  UserRole,
  UserRoleInput,
  VolumeUnit,
} from 'generated/graphql';
import { ActivityHistoryEventType, GeofenceBreachFilterType } from 'types/activityHistory';
import { TFunctioni18n } from 'types/react-i18next';
import { DashboardWidget } from 'types/widgets';

export const dtcEventTypeTranslationMap = (t: TFunctioni18n): Record<DtcEventType, string> => ({
  PENDING: t('common.enums.DtcEventType.PENDING', { defaultValue: 'Pending' }),
  PERMANENT: t('common.enums.DtcEventType.PERMANENT', { defaultValue: 'Permanent' }),
  STORED: t('common.enums.DtcEventType.STORED', { defaultValue: 'Stored' }),
});

export const dtcStatusTranslationMap = (t: TFunctioni18n): Record<DtcStatus, string> => ({
  ACTIVE: t('common.enums.DtcStatus.ACTIVE', { defaultValue: 'Active' }),
  INACTIVE: t('common.enums.DtcStatus.INACTIVE', { defaultValue: 'Inactive' }),
});

export const batteryStateTranslationMap = (t: TFunctioni18n): Record<BatteryState, string> => ({
  NOTIFICATION_CRITICAL: t('common.enums.BatteryState.NOTIFICATION_CRITICAL', { defaultValue: 'Critical' }),
  NOTIFICATION_DRAIN: t('common.enums.BatteryState.NOTIFICATION_DRAIN', { defaultValue: 'Drain' }),
  NOTIFICATION_HIBERNATE: t('common.enums.BatteryState.NOTIFICATION_HIBERNATE', { defaultValue: 'Hibernate' }),
  NOTIFICATION_LOW: t('common.enums.BatteryState.NOTIFICATION_LOW', { defaultValue: 'Low' }),
  NOTIFICATION_OK: t('common.enums.BatteryState.NOTIFICATION_OK', { defaultValue: 'OK' }),
  SOH_LIKELY_TO_FAIL: t('common.enums.BatteryState.SOH_LIKELY_TO_FAIL', { defaultValue: 'Likely to fail' }),
});

export const batterySohTranslationMap = (t: TFunctioni18n): Record<BatterySohState, string> => ({
  GOOD: t('common.enums.BatterySohState.GOOD', { defaultValue: 'Good' }),
  LIKELY_TO_FAIL: t('common.enums.BatterySohState.LIKELY_TO_FAIL', { defaultValue: 'Likely to Fail' }),
  NOT_ESTIMATED: t('common.enums.BatterySohState.NOT_ESTIMATED', { defaultValue: 'Not Estimated' }),
});

export const batteryNotificationTranslationMap = (t: TFunctioni18n): Record<BatteryNotificationType, string> => ({
  [BatteryNotificationType.Unavailable]: t('common.general.unavailable', {
    defaultValue: 'Unavailable',
  }),
  [BatteryNotificationType.Critical]: t('common.enums.BatteryNotificationType.Critical', { defaultValue: 'Critical' }),
  [BatteryNotificationType.Drain]: t('common.enums.BatteryNotificationType.Drain', { defaultValue: 'Drain' }),
  [BatteryNotificationType.Hibernate]: t('common.enums.BatteryNotificationType.Hibernate', {
    defaultValue: 'Hibernate',
  }),
  [BatteryNotificationType.Low]: t('common.enums.BatteryNotificationType.Low', { defaultValue: 'Low' }),
  [BatteryNotificationType.Ok]: t('common.enums.BatteryNotificationType.OK', { defaultValue: 'OK' }),
});

export const dashboardLightTranslationMap = (t: TFunctioni18n): Record<DashboardLightMessageType, string> => ({
  [DashboardLightMessageType.Abs]: t('common.enums.DashboardLightMessageType.Abs', {
    defaultValue: 'ABS',
  }),
  [DashboardLightMessageType.Adblue]: t('common.enums.DashboardLightMessageType.Adblue', {
    defaultValue: 'Adblu',
  }),
  [DashboardLightMessageType.BrakePad]: t('common.enums.DashboardLightMessageType.BrakePad', {
    defaultValue: 'Brake pads',
  }),
  [DashboardLightMessageType.CoolantLevel]: t('common.enums.DashboardLightMessageType.CoolantLevel', {
    defaultValue: 'Coolant level',
  }),
  [DashboardLightMessageType.CoolantTemperature]: t('common.enums.DashboardLightMessageType.CoolantTemperature', {
    defaultValue: 'Coolant temperature',
  }),
  [DashboardLightMessageType.Dpf]: t('common.enums.DashboardLightMessageType.Dpf', {
    defaultValue: 'Diesel Particulate Filter',
  }),
  [DashboardLightMessageType.EngineMilStatus]: t('common.enums.DashboardLightMessageType.EngineMilStatus', {
    defaultValue: 'MIL status',
  }),
  [DashboardLightMessageType.EngineOilLevel]: t('common.enums.DashboardLightMessageType.EngineOilLevel', {
    defaultValue: 'Oil life',
  }),
  [DashboardLightMessageType.EngineOilPressure]: t('common.enums.DashboardLightMessageType.EngineOilPressure', {
    defaultValue: 'Oil pressure',
  }),
  [DashboardLightMessageType.Esp]: t('common.enums.DashboardLightMessageType.Esp', {
    defaultValue: 'Traction Control',
  }),
  [DashboardLightMessageType.Glowplug]: t('common.enums.DashboardLightMessageType.Glowplug', {
    defaultValue: 'Glowplug indicator',
  }),
  [DashboardLightMessageType.Srs]: t('common.enums.DashboardLightMessageType.Srs', {
    defaultValue: 'Supplemental Restraint System',
  }),
  [DashboardLightMessageType.Tpms]: t('common.enums.DashboardLightMessageType.Tpms', {
    defaultValue: 'Tyre Pressure Monitoring',
  }),
  [DashboardLightMessageType.Washer]: t('common.enums.DashboardLightMessageType.Washer', {
    defaultValue: 'Screenwash',
  }),
});

export const dashboardLightStatusTranslationMap = (t: TFunctioni18n): Record<DashboardLightStatus, string> => ({
  OFF: t('common.enums.DashboardLightStatus.OFF', {
    defaultValue: 'Off',
  }),
  ON: t('common.enums.DashboardLightStatus.ON', {
    defaultValue: 'On',
  }),
});

export const periodTranslationMap = (t: TFunctioni18n): Record<Period, string> => ({
  P24h: t('common.enums.Period.P24h', { defaultValue: 'Last 24 hrs' }),
  P30d: t('common.enums.Period.P30d', { defaultValue: 'Last 30 days' }),
  P7d: t('common.enums.Period.P7d', { defaultValue: 'Last 7 days' }),
  P90d: t('common.enums.Period.P90d', { defaultValue: 'Last 90 days' }),
  FOREVER: t('common.enums.Period.FOREVER', { defaultValue: 'Forever' }),
});

export const reportPeriodTranslationMap = (t: TFunctioni18n): Record<ReportPeriod | 'custom', string> => ({
  [ReportPeriod.P24h]: t('common.enums.ReportPeriod.P24h', { defaultValue: 'Last 24 hrs' }),
  [ReportPeriod.P30d]: t('common.enums.ReportPeriod.P30d', { defaultValue: 'Last 30 days' }),
  [ReportPeriod.P7d]: t('common.enums.ReportPeriod.P7d', { defaultValue: 'Last 7 days' }),
  custom: t('common.enums.ReportPeriod.custom', { defaultValue: 'Custom Dates' }),
});

export const reportTypeTranslationMap = (t: TFunctioni18n): Record<ReportType, string> => ({
  [ReportType.Driver]: t('common.enums.ReportType.Driver', { defaultValue: 'Driver Summary' }),
  [ReportType.Trip]: t('common.enums.ReportType.Trip', { defaultValue: 'Trip Summary' }),
  [ReportType.TripDetailed]: t('common.enums.ReportType.TripDetailed', { defaultValue: 'Detailed Trip Report' }),
  [ReportType.Vehicle]: t('common.enums.ReportType.Vehicle', { defaultValue: 'Vehicle Summary' }),
});

export const reportScheduleTranslationMap = (t: TFunctioni18n): Record<ReportSchedule, string> => ({
  [ReportSchedule.Monthly]: t('common.enums.ReportSchedule.Monthly', { defaultValue: 'Runs monthly' }),
  [ReportSchedule.Once]: t('common.enums.ReportSchedule.Once', { defaultValue: 'Runs once' }),
  [ReportSchedule.Weekly]: t('common.enums.ReportSchedule.Weekly', { defaultValue: 'Runs weekly' }),
});

export const daysOfWeekTranslationMap = (t: TFunctioni18n): Record<WeeklyReport, string> => ({
  [WeeklyReport.Mon]: t('common.enums.WeeklyReport.Monday', { defaultValue: 'Monday' }),
  [WeeklyReport.Tue]: t('common.enums.WeeklyReport.Tuesday', { defaultValue: 'Tuesday' }),
  [WeeklyReport.Wed]: t('common.enums.WeeklyReport.Wednesday', { defaultValue: 'Wednesday' }),
  [WeeklyReport.Thu]: t('common.enums.WeeklyReport.Thursday', { defaultValue: 'Thursday' }),
  [WeeklyReport.Fri]: t('common.enums.WeeklyReport.Friday', { defaultValue: 'Friday' }),
  [WeeklyReport.Sat]: t('common.enums.WeeklyReport.Saturday', { defaultValue: 'Saturday' }),
  [WeeklyReport.Sun]: t('common.enums.WeeklyReport.Sunday', { defaultValue: 'Sunday' }),
});

export const userLocaleTranslationMap = (t: TFunctioni18n): Record<UserLocale, string> => ({
  de: t('common.enums.UserLocale.de', { defaultValue: 'German' }),
  en: t('common.enums.UserLocale.en', { defaultValue: 'English' }),
  nl: t('common.enums.UserLocale.nl', { defaultValue: 'Dutch' }),
  ro: t('common.enums.UserLocale.ro', { defaultValue: 'Romainian' }),
});

export const currencySymbolMap: Record<CurrencyUnit, string> = {
  [CurrencyUnit.Chf]: 'CHF',
  [CurrencyUnit.Eur]: 'EUR €',
  [CurrencyUnit.Gbp]: 'GBP £',
  [CurrencyUnit.Usd]: 'USD $',
};

export const deviceDiagnosticTranslationMap = (t: TFunctioni18n): Record<DeviceDiagnosticMessageType, string> => ({
  ACTIVATED: t('common.enums.DeviceDiagnosticMessageType.ACTIVATED', { defaultValue: 'Device Activated' }),
  HEARTBEAT: t('common.enums.DeviceDiagnosticMessageType.HEARTBEAT', { defaultValue: 'Device Heartbeat' }),
  INSERTED: t('common.enums.DeviceDiagnosticMessageType.INSERTED', { defaultValue: 'Reconnected: Device Inserted' }),
  POWER_LOSS: t('common.enums.DeviceDiagnosticMessageType.POWER_LOSS', { defaultValue: 'Disconnected: Power Loss' }),
  POWER_RESTORE: t('common.enums.DeviceDiagnosticMessageType.POWER_RESTORE', {
    defaultValue: 'Reconnected: Power Restored',
  }),
  REMOVED: t('common.enums.DeviceDiagnosticMessageType.REMOVED', { defaultValue: 'Disconnected: Device Removed' }),
  UPGRADE_COMPLETE: t('common.enums.DeviceDiagnosticMessageType.UPGRADE_COMPLETE', {
    defaultValue: 'Upgrade Complete',
  }),
});

export const deviceConnectionStatusTranslationMap = (t: TFunctioni18n): Record<DeviceConnectionStatus, string> => ({
  Connected: t('common.enums.DeviceConnectionStatus.Connected', { defaultValue: 'Connected' }),
  Disconnected: t('common.enums.DeviceConnectionStatus.Disconnected', { defaultValue: 'Disconnected' }),
  NeverConnected: t('common.enums.DeviceConnectionStatus.NeverConnected', { defaultValue: 'Never Connected' }),
  NotReporting: t('common.enums.DeviceConnectionStatus.NotReporting', { defaultValue: 'Not Reporting' }),
});

export const deviceTypeTranslationMap = (t: TFunctioni18n): Record<DeviceType, string> => ({
  Dongle: t('common.enums.DeviceType.Dongle', { defaultValue: 'Dongle' }),
  Embedded: t('common.enums.DeviceType.Embedded', { defaultValue: 'Embedded' }),
});

export const tripStatisticTypeTranslationMap = (
  t: TFunctioni18n,
  {
    distanceInMiles,
    volumeInGallons,
  }: {
    distanceInMiles: boolean;
    volumeInGallons: boolean;
  },
): Record<TripStatisticType, string> => {
  const distanceUnit = distanceInMiles ? 'mi' : 'km';
  const volumeUnit = volumeInGallons ? 'gal' : 'l';

  return {
    ECO_SCORE: t('common.enums.TripStatisticType.ECO_SCORE', { defaultValue: 'Eco Score' }),
    FUEL_CONSUMPTION: t('common.enums.TripStatisticType.FUEL_CONSUMPTION', {
      volumeUnit,
      defaultValue: 'Fuel consumption ({{volumeUnit}})',
    }),
    FUEL_EFFICIENCY: t('common.enums.TripStatisticType.FUEL_EFFICIENCY', {
      volumeUnit,
      distanceUnit,
      defaultValue: 'Fuel efficiency ({{distanceUnit}} / {{volumeUnit}})',
    }),
    IDLE_TIME_HRS: t('common.enums.TripStatisticType.IDLE_TIME_HRS', { defaultValue: 'Idle time (Hrs)' }),
    IDLE_TIME_PCT: t('common.enums.TripStatisticType.IDLE_TIME_PCT', { defaultValue: 'Idle time (%)' }),
    TOTAL_DISTANCE_DRIVEN: t('common.enums.TripStatisticType.TOTAL_DISTANCE_DRIVEN', {
      distanceUnit,
      defaultValue: 'Total distance driven ({{distanceUnit}})',
    }),
    TOTAL_TIME_DRIVEN: t('common.enums.TripStatisticType.TOTAL_TIME_DRIVEN', {
      defaultValue: 'Total time driven (hrs)',
    }),
    TRIP_COUNT: t('common.enums.TripStatisticType.TRIP_COUNT', { defaultValue: 'Number of trips' }),
  };
};

export const bulkUploadStatusTranslationMap = (t: TFunctioni18n): Record<BulkUploadStatus, string> => ({
  CALCULATING_ASSOCIATION_UPDATES: t('common.enums.BulkUploadStatus.CALCULATING_ASSOCIATION_UPDATES', {
    defaultValue: 'Upload in progress: calculating vehicle/driver relationship changes...',
  }),
  CREATED: t('common.enums.BulkUploadStatus.CREATED', {
    defaultValue: 'Upload waiting to start',
  }),
  ERROR: t('common.enums.BulkUploadStatus.ERROR', {
    defaultValue: 'Finished with errors',
  }),
  FINISHED: t('common.enums.BulkUploadStatus.FINISHED', {
    defaultValue: 'Upload complete',
  }),
  PERSISTING_ASSOCIATION_UPDATES: t('common.enums.BulkUploadStatus.PERSISTING_ASSOCIATION_UPDATES', {
    defaultValue: 'Upload in progress: saving vehicle/driver relationship changes...',
  }),
  PERSISTING_ENTITIES: t('common.enums.BulkUploadStatus.PERSISTING_ENTITIES', {
    defaultValue: 'Upload in progress: saving vehicles and drivers...',
  }),
  VALIDATING_RECORDS: t('common.enums.BulkUploadStatus.VALIDATING_RECORDS', {
    defaultValue: 'Upload in progress: validating records...',
  }),
});

export const driverEventTypeTranslationMap = (t: TFunctioni18n): Record<DriverEventType, string> => ({
  IMPACT_AMBER: t('common.enums.DriverEventType.IMPACT_AMBER', {
    defaultValue: 'Medium Impact',
  }),
  IMPACT_GREEN: t('common.enums.DriverEventType.IMPACT_GREEN', {
    defaultValue: 'Soft Impact',
  }),
  IMPACT_RED: t('common.enums.DriverEventType.IMPACT_RED', {
    defaultValue: 'Hard Impact',
  }),
  UNPLUG: t('common.enums.DriverEventType.UNPLUG', {
    defaultValue: 'Unplug',
  }),
  NOT_REPORTING: t('common.enums.DriverEventType.NOT_REPORTING', {
    defaultValue: 'Device not reporting',
  }),
  LONG_IDLING: t('common.enums.DriverEventType.LONG_IDLING', {
    defaultValue: 'Long Idling',
  }),
});

export const crashSeverityTranslationMap = (t: TFunctioni18n): Record<CrashSeverity, string> => ({
  GREEN: t('common.enums.CrashSeverity.GREEN', { defaultValue: 'Soft' }),
  AMBER: t('common.enums.CrashSeverity.AMBER', { defaultValue: 'Medium' }),
  RED: t('common.enums.CrashSeverity.RED', { defaultValue: 'Hard' }),
});

export const confidenceLevelTranslationMap = (t: TFunctioni18n): Record<ConfidenceLevel, string> => ({
  HIGH: t('common.enums.ConfidenceLevel.HIGH', { defaultValue: 'High' }),
  LOW: t('common.enums.ConfidenceLevel.LOW', { defaultValue: 'Low' }),
  MEDIUM: t('common.enums.ConfidenceLevel.MEDIUM', { defaultValue: 'Medium' }),
});

export const consenstStatusTranslationMap = (t: TFunctioni18n): Record<ConsentStatus, string> => ({
  APPROVED: t('common.enums.ConsentStatus.APPROVED', { defaultValue: 'Approved' }),
  REVOKED: t('common.enums.ConsentStatus.REVOKED', { defaultValue: 'Revoked' }),
  SUSPENDED: t('common.enums.ConsentStatus.SUSPENDED', { defaultValue: 'Suspended' }),
});

export const distanceUnitTranslationMap = (t: TFunctioni18n): Record<DistanceUnit, string> => ({
  KILOMETRES: t('common.enums.DistanceUnit.KILOMETRES', { defaultValue: 'km' }),
  MILES: t('common.enums.DistanceUnit.MILES', { defaultValue: 'mi' }),
});

export const fleetMetaFieldTranslationMap = (t: TFunctioni18n): Record<FleetMetaFieldDataType, string> => ({
  BOOLEAN: t('common.enums.FleetMetaFieldDataType.BOOLEAN', { defaultValue: 'Boolean' }),
  DATE: t('common.enums.FleetMetaFieldDataType.DATE', { defaultValue: 'Date' }),
  NUMBER: t('common.enums.FleetMetaFieldDataType.NUMBER', { defaultValue: 'Number' }),
  STRING: t('common.enums.FleetMetaFieldDataType.STRING', { defaultValue: 'String' }),
});

export const fleetNotificationRecipientGroupTranslationMap = (
  t: TFunctioni18n,
): Record<FleetNotificationRecipientGroup | 'customEmail' | 'customTelephone' | 'userId', string> => ({
  ADMINS: t('common.enums.FleetNotificationRecipientGroup.ADMINS', { defaultValue: 'Admins' }),
  ALL_LINE_MANAGERS: t('common.enums.FleetNotificationRecipientGroup.ALL_LINE_MANAGERS', {
    defaultValue: 'Line managers',
  }),
  DIRECT_MANAGERS: t('common.enums.FleetNotificationRecipientGroup.DIRECT_MANAGERS', {
    defaultValue: 'Direct managers',
  }),
  DRIVERS: t('common.enums.FleetNotificationRecipientGroup.DRIVERS', { defaultValue: 'Drivers' }),
  VIEWERS: t('common.enums.FleetNotificationRecipientGroup.VIEWERS', { defaultValue: 'Viewers' }),
  customEmail: t('common.enums.FleetNotificationRecipientGroup.customEmail', { defaultValue: 'Email to: ' }),
  customTelephone: t('common.enums.FleetNotificationRecipientGroup.customTelephone', {
    defaultValue: 'SMS to: ',
  }),
  userId: t('common.enums.FleetNotificationRecipientGroup.userId', { defaultValue: 'User: ' }),
});

export const fuelTypeTranslationMap = (t: TFunctioni18n): Record<FuelType, string> => ({
  DIESEL: t('common.enums.FuelType.DIESEL', { defaultValue: 'Diesel' }),
  ELECTRIC: t('common.enums.FuelType.ELECTRIC', { defaultValue: 'Electric' }),
  HYBRID: t('common.enums.FuelType.HYBRID', { defaultValue: 'Hybrid' }),
  PETROL: t('common.enums.FuelType.PETROL', { defaultValue: 'Petrol' }),
  UNKNOWN: t('common.enums.FuelType.UNKNOWN', { defaultValue: 'Unknown' }),
});

export const geofenceBreachTypeTranslationMap = (t: TFunctioni18n): Record<GeofenceBreachType, string> => ({
  CROSS: t('common.enums.GeofenceBreachType.CROSS', { defaultValue: 'Cross' }),
  ENTER: t('common.enums.GeofenceBreachType.ENTER', { defaultValue: 'Enter' }),
  EXIT: t('common.enums.GeofenceBreachType.EXIT', { defaultValue: 'Exit' }),
});

export const geofenceColorTranslationMap = (t: TFunctioni18n): Record<GeofenceColor, string> => ({
  BLUE: t('common.enums.GeofenceColor.BLUE', { defaultValue: 'blue' }),
  BROWN: t('common.enums.GeofenceColor.BROWN', { defaultValue: 'brown' }),
  GRAY: t('common.enums.GeofenceColor.GRAY', { defaultValue: 'gray' }),
  GREEN: t('common.enums.GeofenceColor.GREEN', { defaultValue: 'green' }),
  PURPLE: t('common.enums.GeofenceColor.PURPLE', { defaultValue: 'purple' }),
});

export const leaderboardTypeTranslationMap = (
  t: TFunctioni18n,
): Record<LeaderboardType, (count: number) => string> => ({
  BOTTOM: (count) => t('common.enums.LeaderboardType.BOTTOM', { count, defaultValue: 'Bottom {{count}}' }),
  TOP: (count) => t('common.enums.LeaderboardType.TOP', { count, defaultValue: 'Top {{ count}}' }),
});

export const notificationTypeTranslationMap = (t: TFunctioni18n): Record<NotificationType, string> => ({
  Email: t('common.enums.NotificationType.Email', { defaultValue: 'Email' }),
  Portal: t('common.enums.NotificationType.Portal', { defaultValue: 'Portal' }),
  Sms: t('common.enums.NotificationType.Sms', { defaultValue: 'SMS' }),
});

export const odoSourceTranslationMap = (t: TFunctioni18n): Record<OdoSource, string> => ({
  CAN_DASHBOARD: t('common.enums.OdoSource.CAN_DASHBOARD', { defaultValue: 'CAN / Dashboard' }),
  GPS: t('common.enums.OdoSource.GPS', { defaultValue: 'GPS' }),
});

export const topicTranslationMap = (t: TFunctioni18n): Record<Topic, string> => ({
  batteryCritical: t('common.enums.Topic.batteryCritical', { defaultValue: 'Battery Critical' }),
  batteryHibernate: t('common.enums.Topic.batteryHibernate', { defaultValue: 'Battery Hibernate' }),
  batteryLikelyToFail: t('common.enums.Topic.batteryLikelyToFail', { defaultValue: 'Battery Likely to Fail' }),
  batteryLow: t('common.enums.Topic.batteryLow', { defaultValue: 'Battery Low' }),
  dashlights: t('common.enums.Topic.dashlights', { defaultValue: 'Dashlights' }),
  dtcPending: t('common.enums.Topic.dtcPending', { defaultValue: 'DTC Pending' }),
  dtcPermanent: t('common.enums.Topic.dtcPermanent', { defaultValue: 'DTC Permanent' }),
  dtcStored: t('common.enums.Topic.dtcStored', { defaultValue: 'DTC Stored' }),
  geofence: t('common.enums.Topic.geofence', { defaultValue: 'Geofence Breaches' }),
  impact: t('common.enums.Topic.impact', { defaultValue: 'Impacts / FNOLs' }),
  mil: t('common.enums.Topic.mil', { defaultValue: 'Engine MIL Light On' }),
  removed: t('common.enums.Topic.removed', { defaultValue: 'Device Removed' }),
  serviceReminder: t('common.enums.Topic.serviceReminder', { defaultValue: 'Service Reminders' }),
});

export const tripEcoScoreTypeTranslationMap = (t: TFunctioni18n): Record<TripEcoScoreType, string> => ({
  ACCELERATION_SCORE: t('common.enums.TripEcoScoreType.ACCELERATION_SCORE', { defaultValue: 'Acceleration Score' }),
  DECELERATION_SCORE: t('common.enums.TripEcoScoreType.DECELERATION_SCORE', { defaultValue: 'Deceleration Score' }),
  IDLING_SCORE: t('common.enums.TripEcoScoreType.IDLING_SCORE', { defaultValue: 'Idling Score' }),
  OVERALL_ECO_SCORE: t('common.enums.TripEcoScoreType.OVERALL_ECO_SCORE', { defaultValue: 'Overall Eco Score' }),
  SPEED_SCORE: t('common.enums.TripEcoScoreType.SPEED_SCORE', { defaultValue: 'Speed Score' }),
});

export const tripEventTypeTranslationMap = (t: TFunctioni18n): Record<TripEventType, string> => ({
  GEOFENCE_BREACH: t('common.enums.TripEventType.GEOFENCE_BREACH', { defaultValue: 'Geofence Breach' }),
  HARD_ACCELERATION: t('common.enums.TripEventType.HARD_ACCELERATION', { defaultValue: 'Hard Acceleration' }),
  HARD_BRAKING: t('common.enums.TripEventType.HARD_BRAKING', { defaultValue: 'Hard Braking' }),
  HARD_CORNERING_LEFT: t('common.enums.TripEventType.HARD_CORNERING_LEFT', { defaultValue: 'Hard Cornering Left' }),
  HARD_CORNERING_RIGHT: t('common.enums.TripEventType.HARD_CORNERING_RIGHT', { defaultValue: 'Hard Cornering Right' }),
  IDLE_END: t('common.enums.TripEventType.IDLE_END', { defaultValue: 'Idle End' }),
  IDLE_START: t('common.enums.TripEventType.IDLE_START', { defaultValue: 'Idle Start' }),
  OVER_SPEED_END: t('common.enums.TripEventType.OVER_SPEED_END', { defaultValue: 'Overspeed End' }),
  OVER_SPEED_START: t('common.enums.TripEventType.OVER_SPEED_START', { defaultValue: 'Overspeed Start' }),
  LONG_IDLE: t('common.enums.TripEventType.LONG_IDLE', { defaultValue: 'Long Idle' }),
});

export const tripSortTypeTranslationMap = (t: TFunctioni18n): Record<TripSortType, string> => ({
  END_TIME_MOST_RECENT_FIRST: t('common.enums.TripSortType.END_TIME_MOST_RECENT_FIRST', {
    defaultValue: 'Most recent trips (by end date)',
  }),
  END_TIME_OLDEST_FIRST: t('common.enums.TripSortType.END_TIME_OLDEST_FIRST', {
    defaultValue: 'Oldest trips (by end date)',
  }),
  START_TIME_MOST_RECENT_FIRST: t('common.enums.TripSortType.START_TIME_MOST_RECENT_FIRST', {
    defaultValue: 'Most recent events first',
  }),
  START_TIME_OLDEST_FIRST: t('common.enums.TripSortType.START_TIME_OLDEST_FIRST', {
    defaultValue: 'Oldest events first',
  }),
});

export const userRoleTranslationMap = (t: TFunctioni18n): Record<UserRole, string> => ({
  ADMIN: t('common.enums.UserRole.ADMIN', {
    defaultValue: 'Admin',
  }),
  MANAGER: t('common.enums.UserRole.MANAGER', {
    defaultValue: 'Manager',
  }),
  SUPERADMIN: t('common.enums.UserRole.SUPERADMIN', {
    defaultValue: 'SuperAdmin',
  }),
  VIEWER: t('common.enums.UserRole.VIEWER', {
    defaultValue: 'Viewer',
  }),
});

export const userRoleInputTranslationMap = (t: TFunctioni18n): Record<UserRoleInput, string> => ({
  ADMIN: t('common.enums.UserRoleInput.ADMIN', {
    defaultValue: 'Admin',
  }),
  MANAGER: t('common.enums.UserRoleInput.MANAGER', {
    defaultValue: 'Manager',
  }),
  SUPERADMIN: t('common.enums.UserRoleInput.SUPERADMIN', {
    defaultValue: 'SuperAdmin',
  }),
  VIEWER: t('common.enums.UserRoleInput.VIEWER', {
    defaultValue: 'Viewer',
  }),
  DRIVER_ONLY: t('common.enums.UserRoleInput.DRIVER_ONLY', {
    defaultValue: 'Driver',
  }),
});

export const volumeUnitTranslationMap = (t: TFunctioni18n): Record<VolumeUnit, string> => ({
  GALLON: t('common.enums.VolumeUnit.GALLON', {
    defaultValue: 'Gallon',
  }),
  LITRE: t('common.enums.VolumeUnit.LITRE', {
    defaultValue: 'Litre',
  }),
});

export const activityHistoryEventTypeTranslationMap = (t: TFunctioni18n): Record<ActivityHistoryEventType, string> => ({
  battery: t('common.enums.ActivityHistoryEventType.battery', {
    defaultValue: 'Battery events',
  }),
  dashboard_light: t('common.enums.ActivityHistoryEventType.dashboard_light', {
    defaultValue: 'Dashboard light events',
  }),
  disconnected: t('common.enums.ActivityHistoryEventType.disconnected', {
    defaultValue: 'Disconnected events',
  }),
  dtc: t('common.enums.ActivityHistoryEventType.dtc', {
    defaultValue: 'DTC events',
  }),
  impact: t('common.enums.ActivityHistoryEventType.impact', {
    defaultValue: 'Impact / FNOL events',
  }),
  longIdling: t('common.enums.DriverEventType.LONG_IDLING', {
    defaultValue: 'Long idling',
  }),
  reconnected: t('common.enums.ActivityHistoryEventType.reconnected', {
    defaultValue: 'Reconnected events',
  }),
  trip: t('common.enums.ActivityHistoryEventType.trip', {
    defaultValue: 'Trip events',
  }),
});

export const geofenceBreachFilterTypeTMap = (t: TFunctioni18n): Record<GeofenceBreachFilterType, string> => ({
  All: t('common.enums.GeofenceBreachFilterType.All', { defaultValue: 'All' }),
  None: t('common.enums.GeofenceBreachFilterType.None', { defaultValue: 'None' }),
});

export const warningLevelTMap = (t: TFunctioni18n): Record<WarningLevel, string> => ({
  CAUTION: t('common.enums.WarningLevel.CAUTION', { defaultValue: 'Caution' }),
  OK: t('common.enums.WarningLevel.OK', { defaultValue: 'OK' }),
  WARNING: t('common.enums.WarningLevel.WARNING', { defaultValue: 'Warning' }),
});

export const dashboardWidgetTMap = (t: TFunctioni18n): Record<DashboardWidget, string> => ({
  batteryEventCountWidget: t('common.enums.DashboardWidget.batteryEventCountWidget', {
    defaultValue: 'Battery Events',
  }),
  dashboardLightEventCountWidget: t('common.enums.DashboardWidget.dashboardLightEventCountWidget', {
    defaultValue: 'Dashboard Light Events',
  }),
  driverEventCountWidget: t('common.enums.DashboardWidget.driverEventCountWidget', { defaultValue: 'Driver Events' }),
  dtcEventCountWidget: t('common.enums.DashboardWidget.dtcEventCountWidget', { defaultValue: 'DTC Events' }),
  ecoScoreWidget: t('common.enums.DashboardWidget.ecoScoreWidget', { defaultValue: 'Fleet Eco Score' }),
  fleetDrivingStatsWidget: t('common.enums.DashboardWidget.fleetDrivingStatsWidget', {
    defaultValue: 'Fleet Driving Stats',
  }),
  idleTimeLeaderboardWidget: t('common.enums.DashboardWidget.idlePerfomanceWidget', {
    defaultValue: 'Idle time leaderboard',
  }),
  leaderboardWidget: t('common.enums.DashboardWidget.leaderboardWidget', { defaultValue: 'Leaderboard' }),
  utilisationWidget: t('common.general.utilisation', { defaultValue: 'Utilisation' }),
});

export const deviceOemTypeToNativeType: Record<string, DeviceType> = {
  bmw: DeviceType.Embedded,
  caruso: DeviceType.Embedded,
  ford: DeviceType.Embedded,
  tm8: DeviceType.Dongle,
};
