import { useContext } from 'react';
import { useRecoilValue } from 'recoil';

import { I18nContext } from 'common/useT';
import useRole from 'components/User/useRole';
import { useAvailableDrivers } from 'components/Vehicle/Detail/hooks';
import { vehicleListState } from 'components/Vehicle/state';
import { VehicleListCell } from 'components/Vehicle/VehicleList/useVehicleListColumns';
import DriverSelector from 'components/Vehicle/VehicleList/VehicleListDriverCell/DriverSelector';

const VehicleListDriverCell = ({
  row: {
    original: {
      associations: { driver, device },
    },
  },
}: VehicleListCell<string>) => {
  const i18nContext = useContext(I18nContext);
  const { isInEditMode } = useRecoilValue(vehicleListState);
  const availableDrivers = useAvailableDrivers();
  const { id: driverId, name: driverName } = driver ?? { id: undefined, name: undefined };
  const { isManager } = useRole();

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { unavailable_text },
    },
  } = i18nContext;

  if (!availableDrivers || !device?.id || !isInEditMode || !isManager) {
    return <span>{driverName}</span>;
  }

  return (
    <DriverSelector
      drivers={availableDrivers}
      currentDriver={driverId ? { id: driverId, name: driverName ?? unavailable_text } : undefined}
      deviceId={device.id}
    />
  );
};

export default VehicleListDriverCell;
