import { useLazyQuery, useMutation } from '@apollo/client';
import { DetailedHTMLProps, HTMLAttributes, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import Modal, { useModal } from 'atoms/Modal';
import Spinner from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { VehicleEditForm, VehicleEditFormData } from 'components/Vehicle/Detail/Edit/VehicleEditForm';
import ExistingVehicleDetails from 'components/Vehicle/VehicleList/VehicleListToolbar/ExistingVehicleDetails';
import { CreateVehicleDoc, GetNewAggregateVehicleDetailsDoc, GetVehicleListDoc } from 'generated/graphql';
import { getError, useQ } from 'utils/apolloClient';

const Trigger = (props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div {...props}>
      <span className="text-sm space-x-0.5 flex-center">
        <IonIcon className="text-lg" name="addCircleOutline" />

        <span>
          {tSafe('components.Vehicle.VehicleList.VehicleListToolbar.AddVehicleToolbarItem.Trigger.add-vehicle', {
            defaultValue: 'Add Vehicle',
          })}
        </span>
      </span>
    </div>
  );
};

const AddVehicleToolbarItem = () => {
  const i18nContext = useContext(I18nContext);
  const { tSafe } = i18nContext ?? {};
  const currentFleetId = useCurrentFleetId();
  useQ(GetVehicleListDoc, {
    variables: { fleetIds: useCurrentFleetId() },
    fetchPolicy: 'cache-only',
  });

  const [existingVehicleId, setExistingVehicleId] = useState<string>();
  const [newVehicleId, setNewVehicleId] = useState<string>();
  const [useDriverFromExistingAssociation, setUseDriverFromExistingAssociation] = useState(false);
  const [showVehicleEditForm, setShowVehicleEditForm] = useState(true);
  const [hideEditFormButtons, setHideEditFormButtons] = useState(false);
  const [createVehicleErrorMessage, setCreateVehicleErrorMessage] = useState<string>();
  const { props, closeModal } = useModal({
    title:
      tSafe &&
      tSafe(
        'components.Vehicle.VehicleList.VehicleListToolbar.AddVehicleToolbarItem.AddVehicleToolbarItem.create-vehicle',
        {
          defaultValue: 'Create Vehicle',
        },
      ),
    trigger: <Trigger className="ui-button-dark" />,
    showCloseButton: false,
  });
  const [getVehicleDetails, { client }] = useLazyQuery(GetNewAggregateVehicleDetailsDoc, {
    onCompleted: (newVehicleData) => {
      if (newVehicleData) {
        const [newVehicle] = newVehicleData.aggregatedVehicleDetails.data;

        client.cache.updateQuery({ query: GetVehicleListDoc, variables: { fleetIds: currentFleetId } }, (data) => {
          const currVehicles = data?.aggregatedVehicleDetails.data ?? [];
          const index = currVehicles.findIndex((x) => x.associations.device?.id === newVehicle.associations.device?.id);
          if (index !== -1) {
            const newList = [...currVehicles];
            newList[index] = newVehicle;
            return {
              aggregatedVehicleDetails: { count: data?.aggregatedVehicleDetails.count ?? 0, data: [...newList] },
            };
          }
          return {
            aggregatedVehicleDetails: {
              count: data?.aggregatedVehicleDetails.count ?? 0,
              data: [newVehicle, ...currVehicles],
            },
          };
        });
      }
    },
  });
  const [createVehicle, { loading: createVehicleLoading, reset: resetCreateVehicle }] = useMutation(CreateVehicleDoc, {
    onCompleted: async (data) => {
      resetCreateVehicle();
      setShowVehicleEditForm(false);
      setExistingVehicleId(undefined);
      setNewVehicleId(data.createVehicle.id);
      await getVehicleDetails({ variables: { vehicleId: data.createVehicle.id } });
    },
    onError: (apolloError) => {
      const error = getError(apolloError);

      setHideEditFormButtons(false);

      if (error?.extensions.vehicleId) {
        setExistingVehicleId(error.extensions.vehicleId);
      } else {
        setCreateVehicleErrorMessage(error?.message);
      }
    },
  });

  if (!i18nContext || !tSafe) return null;

  const {
    commonTranslations: {
      general: { close_text, here_text },
    },
  } = i18nContext;

  const onChangeUseDriverCheckbox = (checked: boolean) => setUseDriverFromExistingAssociation(checked);

  const resetState = () => {
    resetCreateVehicle();
    setExistingVehicleId(undefined);
    setCreateVehicleErrorMessage(undefined);
    setNewVehicleId(undefined);
    setUseDriverFromExistingAssociation(false);
    setShowVehicleEditForm(true);
    setHideEditFormButtons(false);
  };

  const onSubmit = async ({ externalId, ...vehicle }: VehicleEditFormData) => {
    if (!vehicle || !externalId) return;

    setCreateVehicleErrorMessage(undefined);
    setHideEditFormButtons(true);

    createVehicle({
      variables: {
        vehicle,
        fleetId: currentFleetId,
        externalId: externalId!,
        overrideExistingAssociation: !!existingVehicleId,
        useDriverFromExistingAssociation: useDriverFromExistingAssociation,
      },
    });
  };

  return (
    <Modal {...props} contentClassName="w-60" title={showVehicleEditForm ? 'Create Vehicle' : 'Success!'}>
      {createVehicleErrorMessage && <span className="text-md text-error font-bold">{createVehicleErrorMessage}</span>}

      {newVehicleId && (
        <span className="text-md">
          <span>
            {tSafe(
              'components.Vehicle.VehicleList.VehicleListToolbar.AddVehicleToolbarItem.AddVehicleToolbarItem.you-can-view-your-vehicle',
              {
                defaultValue: 'You can view your newly-created vehicle',
              },
            )}
          </span>

          <Link
            target="_blank"
            className=" text-dark-gray underline"
            to={`/${currentFleetId}/vehicles/${newVehicleId}`}
          >
            {here_text}
          </Link>
        </span>
      )}

      {createVehicleLoading && (
        <div className="ml-4 my-1 flex items-center">
          <Spinner className="!text-[0.5em]" />

          <span className="ml-4 text-md">
            {tSafe(
              'components.Vehicle.VehicleList.VehicleListToolbar.AddVehicleToolbarItem.AddVehicleToolbarItem.creating-vehicle',
              {
                defaultValue: 'Creating vehicle... Please be patient - this may take a minute',
              },
            )}
          </span>
        </div>
      )}

      {existingVehicleId && (
        <ExistingVehicleDetails
          vehicleId={existingVehicleId}
          onChangeDriverCheckbox={onChangeUseDriverCheckbox}
          creationMode
        />
      )}

      {showVehicleEditForm ? (
        <VehicleEditForm onSubmit={onSubmit} onCancel={resetState} creationMode hideButtons={hideEditFormButtons} />
      ) : (
        <div className="flex flex-col">
          <Button
            className="mt-1 border-px px-1 rounded-4 text-md w-6"
            onClick={() => {
              closeModal();
              resetState();
            }}
          >
            {close_text}
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default AddVehicleToolbarItem;
