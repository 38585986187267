import { Link } from 'react-router-dom';

import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { VehicleListCell } from 'components/Vehicle/VehicleList/useVehicleListColumns';

const LicencePlateCell = ({ value, row }: VehicleListCell<string | undefined | null>) => {
  const currentFleetId = useCurrentFleetId();

  return (
    <Link
      to={`/${currentFleetId}/vehicles/${row.original.vehicle.id}`}
      className="text-left text-hover hover:underline uppercase"
    >
      {value ?? '-----'}
    </Link>
  );
};

export default LicencePlateCell;
