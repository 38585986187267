import { atom } from 'recoil';

import { UserListColumnId } from 'components/User/UserList/useUserListColumns';

/** @package */
export const userListState = atom({
  key: 'userListState',
  default: {
    isInEditMode: false,
    uniqueValues: {} as Partial<Record<UserListColumnId, NonNullable<string | null | undefined>[]>>,
    onReset: () => {},
  },
});
