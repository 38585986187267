import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { I18nContext } from 'common/useT';
import { DriverListCell } from 'components/Driver/DriverList/useDriverListColumns';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';

const DriverNameCell = ({ value: name, row }: DriverListCell<string | undefined | null>) => {
  const i18nContext = useContext(I18nContext);
  const currentFleetId = useCurrentFleetId();

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { unavailable_text },
    },
  } = i18nContext;

  if (!name) {
    return <>{unavailable_text}</>;
  }

  return (
    <Link to={`/${currentFleetId}/drivers/${row.original.id}`} className="text-hover hover:underline">
      {name}
    </Link>
  );
};

export default DriverNameCell;
