import { useContext } from 'react';
import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { vehicleListState } from 'components/Vehicle/state';
import { cx } from 'utils';

const VehicleListEditModeToolbarItem = () => {
  const i18nContext = useContext(I18nContext);
  const [state, setVehicleListState] = useRecoilState(vehicleListState);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { edit_mode_text },
    },
  } = i18nContext;

  return (
    <span className="flex-center">
      <Tooltip
        text={tSafe(
          'components.Vehicle.VehicleList.VehicleListToolbar.VehicleListEditModeToolbarItem.hide-show-columns',
          { defaultValue: 'Hide/Show columns' },
        )}
      >
        <Button
          onClick={() => setVehicleListState({ ...state, isInEditMode: !state.isInEditMode })}
          className="text-lg flex-center ui-button-dark"
        >
          <IonIcon
            name="settingsOutline"
            aria-label={edit_mode_text}
            className={cx(!state.isInEditMode && 'opacity-40')}
          />
        </Button>
      </Tooltip>
    </span>
  );
};

export default VehicleListEditModeToolbarItem;
