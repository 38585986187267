import { useMutation } from '@apollo/client';
import { useMemo } from 'react';

import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { GetFleetDriversQuery, UpdateAssociationDoc } from 'generated/graphql';
import { SelectOption } from 'types';

interface DriverSelectorProps {
  drivers: GetFleetDriversQuery['driverDetails'];
  currentDriver?: { id: string; name: string };
  deviceId: string;
}

const DriverSelector = ({ drivers, currentDriver, deviceId }: DriverSelectorProps) => {
  const [updateAssociation] = useMutation(UpdateAssociationDoc);

  const driverOptions = useMemo(
    (): SelectOption[] => drivers.map((driver) => ({ label: driver.user.name ?? '', value: driver.id })),
    [drivers],
  );

  const onDriverClick = (driverId: string) => {
    const driver = drivers.find((driver) => driver.id === driverId)!;

    updateAssociation({ variables: { deviceId, driverId: driver.id, withVehicleDetailsAssociations: true } });
  };

  const { getProps } = useDropdownSelect(driverOptions, {
    onSelect: (item: SelectOption) => onDriverClick(item.value),
    initialItem: currentDriver && { label: currentDriver.name, value: currentDriver.id },
    searchable: true,
  });

  return <DropdownSelect {...getProps()} />;
};

export default DriverSelector;
