import { useMutation } from '@apollo/client';
import { useContext } from 'react';

import Button from 'atoms/Button';
import ButtonWithConfirmModal from 'atoms/ButtonWithConfirmModal';
import IonIcon from 'atoms/IonIcon';
import Modal from 'atoms/Modal';
import { CenteredSpinner } from 'atoms/Spinner';
import Tooltip from 'atoms/Tooltip';
import { userRoleToInputMap } from 'common/userRoleToInputConverter';
import { I18nContext } from 'common/useT';
import UserEditForm, { UserEditFormData } from 'components/User/UserList/UserEditForm';
import { UserListCell, UserListItem } from 'components/User/UserList/useUserListColumns';
import { ApiError, DeleteUserDoc, SendPasswordResetEmailDoc, UpdateUserDoc } from 'generated/graphql';
import { cx, entries } from 'utils';
import { getError } from 'utils/apolloClient';
import { errorToast, successToast } from 'utils/toasts';

const UserListActionsCell = ({ value: user }: UserListCell<UserListItem>) => {
  const i18nContext = useContext(I18nContext);
  const [sendPasswordResetEmail] = useMutation(SendPasswordResetEmailDoc, {
    variables: { userId: user.id },
    onCompleted: () =>
      successToast(
        tSafe('components.User.UserList.UserListActionCell.toasts.email-sent', {
          defaultValue: 'The email has been sent',
        }),
      ),
  });

  const [deleteUser, { loading: deleting }] = useMutation(DeleteUserDoc, {
    variables: { id: user.id },
    optimisticResponse: { deleteUser: { __typename: 'User', id: user.id } },
    update: (cache, { data }) => {
      if (!data) return;

      cache.evict({ id: cache.identify(data.deleteUser) });
      cache.gc();
    },
    onCompleted: () =>
      successToast(
        tSafe('components.User.UserList.UserListActionCell.toasts.user-deleted', {
          defaultValue: 'User deleted',
        }),
      ),
  });

  const [updateUser, { loading: updating }] = useMutation(UpdateUserDoc, {
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
    onCompleted: () =>
      successToast(
        tSafe('components.User.UserList.UserListActionCell.toasts.user-updated', {
          defaultValue: 'User updated',
        }),
      ),
  });

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      forms: {
        buttons: { cancel_text, delete_text },
      },
    },
  } = i18nContext;

  const onSubmit = (data: UserEditFormData, dirtyFields: { [key in keyof UserEditFormData]?: boolean | boolean[] }) => {
    const dirtyData = entries(data).reduce((acc, [key, value]) => {
      if (dirtyFields[key]) {
        // @ts-ignore
        acc[key] = value;
      }
      return acc;
    }, {} as Partial<UserEditFormData>);

    updateUser({ variables: { ...dirtyData, id: user.id } });
  };

  return (
    <span className="flex text-md gap-1">
      <Modal
        title={tSafe('components.User.UserList.UserListActionCell.modals.update-user.title', {
          defaultValue: 'Update User',
        })}
        triggerAsChild
        trigger={
          <span>
            <Tooltip text="Edit user" side="right">
              <Button className="ui-button-dark mr-0.5">
                <IonIcon name="pencilOutline" />
              </Button>
            </Tooltip>
          </span>
        }
        contentClassName="w-80"
      >
        {(deleting || updating) && (
          <div className="flex absolute top-1/2 left-1/2">
            <CenteredSpinner className="z-700" />
          </div>
        )}

        <div className={cx((deleting || updating) && 'opacity-20 pointer-events-none')}>
          <UserEditForm
            onSubmit={onSubmit}
            initialState={{
              ...user,
              role: userRoleToInputMap.get(user.role ?? 'none'),
              fleetIds: user.fleets.map((fleet) => fleet.id),
              isDriver: !!user.driverId,
            }}
          />
        </div>
      </Modal>

      <Tooltip text="Reset password" side="right">
        <ButtonWithConfirmModal
          className="text-amber"
          confirmTitle={tSafe('components.User.UserList.UserListActionCell.modals.reset-password.title', {
            defaultValue: 'Reset password',
          })}
          confirmContent={tSafe('components.User.UserList.UserListActionCell.modals.reset-password.content', {
            defaultValue: 'Send password reset email to {{recipient}}?',
            recipient: user.email,
          })}
          confirmButtonText={tSafe('components.User.UserList.UserListActionCell.modals.reset-password.confirm-button', {
            defaultValue: 'Send',
          })}
          rejectButtonText={cancel_text}
          confirmYesButtonClassName="flex-center min-w-6 px-1 py-0.5 border rounded-4 text-success"
          confirmNoButtonClassName="flex-center min-w-6 px-1 py-0.5 border rounded-4"
          onConfirm={() => sendPasswordResetEmail()}
        >
          <IonIcon name="syncOutline" className="ui-button-dark mr-0.5" />
        </ButtonWithConfirmModal>
      </Tooltip>

      <Tooltip text="Delete user" side="right">
        <ButtonWithConfirmModal
          className="text-error"
          confirmTitle={tSafe('components.User.UserList.UserListActionCell.modals.delete-user.title', {
            defaultValue: 'Delete User',
          })}
          confirmContent={tSafe('components.User.UserList.UserListActionCell.modals.delete-user.content', {
            defaultValue: 'Are you sure you want to delete {{userNameOrEmail}}?',
            userNameOrEmail: user.name === '' ? user.email : user.name,
          })}
          confirmButtonText={delete_text}
          rejectButtonText={cancel_text}
          confirmYesButtonClassName="flex-center min-w-6 px-1 py-0.5 border rounded-4 text-error"
          confirmNoButtonClassName="flex-center min-w-6 px-1 py-0.5 border rounded-4"
          onConfirm={() => deleteUser()}
        >
          <IonIcon name="trashOutline" className="ui-button-dark" />
        </ButtonWithConfirmModal>
      </Tooltip>
    </span>
  );
};

export default UserListActionsCell;
