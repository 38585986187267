import { PropsWithChildren, VoidFunctionComponent } from 'react';
import { CellProps, Column, ColumnInstance, FilterType } from 'react-table';

import { getHeaderRenderer } from 'atoms/react-table/TableHeader';

export default class ColumnCollection<T extends object, K extends string> {
  private columns: Column<T>[] = [];
  private header: ReturnType<typeof getHeaderRenderer>;

  constructor(headerComponent: VoidFunctionComponent<any>) {
    this.header = getHeaderRenderer(headerComponent);
  }

  public add = <R extends any>(
    id: K,
    label: string,
    accessor: (item: T) => R,
    {
      component: Component,
      containerClassName,
      filter,
      ...options
    }: {
      component?: VoidFunctionComponent<PropsWithChildren<CellProps<T, R>>>;
      containerClassName?: string;
      filter?: {
        function: FilterType<T>;
        component: VoidFunctionComponent<{ column: ColumnInstance }>;
      };
    } & Partial<Omit<Column<T>, 'id' | 'filter' | 'Filter' | 'Cell' | 'accessor' | 'Header'>> = {},
  ) => {
    this.columns.push({
      id,
      accessor,
      Header: this.header(label),
      Cell: (props: PropsWithChildren<CellProps<T, any>>) => (
        <div className={containerClassName}>{Component ? <Component {...props} /> : props.value}</div>
      ),
      filter: filter?.function,
      Filter: filter?.component,
      ...options,
    });

    return this;
  };

  public toArray = () => this.columns;
}
