import { useContext } from 'react';

import Icon from 'atoms/Icon';
import Tooltip from 'atoms/Tooltip';
import { batteryNotificationIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import { VehicleListCell } from 'components/Vehicle/VehicleList/useVehicleListColumns';
import { BatteryNotificationType } from 'generated/graphql';
import { cx } from 'utils';

const BatteryStatusCell = ({ value }: VehicleListCell<BatteryNotificationType | undefined | null>) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { unavailable_text: unavailableText },
      enums: { batteryNotificationTypeDescriptionMap },
    },
  } = i18nContext;

  return (
    <Tooltip
      text={tSafe('components.Vehicle.VehicleList.cells.BatteryStatusCell.battery-status-is-value', {
        defaultValue: 'Battery status is {{statusValue}}',
        statusValue: value ? batteryNotificationTypeDescriptionMap[value] : unavailableText,
      })}
      side="right"
    >
      <Icon
        name={value ? batteryNotificationIconMap[value][0] : 'batteryNotEstimated'}
        className={cx('!w-3', value ? batteryNotificationIconMap[value][1] : 'text-gray-400')}
      />
    </Tooltip>
  );
};

export default BatteryStatusCell;
