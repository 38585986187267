import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { useRecoilState } from 'recoil';

import FullPageTable, { sortDirectionToString, stringToSortDirection } from 'atoms/react-table/FullPageTable';
import useColumnUniqueValues from 'atoms/react-table/tableFilters/useColumnUniqueValues';
import useMinMaxValues from 'atoms/react-table/tableFilters/useMinMaxValues';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useSettings from 'components/Settings/useSettings';
import ResetUrlParamsToolbarItem from 'components/Toolbar/ResetUrlParamsToolbarItem';
import { vehicleListState } from 'components/Vehicle/state';
import {
  useVehicleListColumns,
  VehicleListColumnId,
  vehicleListMinMaxValueColumns,
  vehicleListUniqueValueColumns,
} from 'components/Vehicle/VehicleList/useVehicleListColumns';
import {
  AggregateSortByInput,
  BatteryNotificationType,
  BatterySohState,
  DashboardLightMessageType,
  DeviceConnectionStatus,
  DtcEventType,
  GetVehicleListDoc,
  ListSortDirection,
  MilStatus,
  StateAggregationFilterInput,
  StateAggregatorSortField,
} from 'generated/graphql';
import { cx, entries, reverseRecord, withTitle } from 'utils';
import { useQ } from 'utils/apolloClient';

const vehicleColumnIdToSortFieldMap: Omit<Record<VehicleListColumnId, StateAggregatorSortField>, 'unassigned'> = {
  batteryCharge: StateAggregatorSortField.BatteryVoltage,
  batteryHealth: StateAggregatorSortField.BatteryHealthState,
  batteryStatus: StateAggregatorSortField.LatestBatteryNotification,
  dashboardLights: StateAggregatorSortField.ActiveDashboardLightCount,
  deviceStatus: StateAggregatorSortField.DeviceStatus,
  distanceDriven: StateAggregatorSortField.TotalDistanceDriven,
  driver: StateAggregatorSortField.DriverName,
  dtcPending: StateAggregatorSortField.DtcPending,
  dtcPermanent: StateAggregatorSortField.DtcPermanent,
  dtcStored: StateAggregatorSortField.DtcStored,
  fuel: StateAggregatorSortField.FuelConsumption,
  impactStatus: StateAggregatorSortField.NumVehicleImpacts,
  licencePlate: StateAggregatorSortField.VehicleLicencePlate,
  make: StateAggregatorSortField.VehicleMake,
  milStatus: StateAggregatorSortField.MilStatus,
  model: StateAggregatorSortField.VehicleModel,
};

const sortFieldMapToVehicleColumnId = reverseRecord(vehicleColumnIdToSortFieldMap);

const VehicleList = () => {
  const i18nContext = useContext(I18nContext);
  const [pageOffset, setPageOffset] = useState(0);
  const [sortBy, setSortBy] = useState<AggregateSortByInput | null>({
    field: StateAggregatorSortField.DeviceStatus,
    direction: ListSortDirection.Asc,
  });
  const history = useHistory();
  const maxItemsPerPage = 20;

  const [selectedApiFilters, setSelectedApiFilters] = useState<StateAggregationFilterInput>();
  const { data, loading, refetch } = useQ(GetVehicleListDoc, {
    variables: {
      fleetIds: useCurrentFleetId(),
      filters: selectedApiFilters,
      sortBy,
      limit: maxItemsPerPage,
      offset: pageOffset * maxItemsPerPage,
    },
    fetchPolicy: 'cache-first',
    // nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  // useAvailableDrivers(useCurrentFleetId(), { fetchPolicy: 'cache-first' }); // preloads drivers

  const urlItems = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const [{ isInEditMode }, setVehicleListState] = useRecoilState(vehicleListState);
  const { hiddenColumns } = useSettings().vehicleListSettings;

  const vehicles = useMemo(() => data?.data ?? [], [data]);

  const table = useTable(
    {
      columns: useVehicleListColumns(),
      data: vehicles,
      initialState: {
        pageSize: maxItemsPerPage,
        hiddenColumns,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const uniqueValues = useColumnUniqueValues<typeof vehicles[0], VehicleListColumnId>(
    vehicles,
    vehicleListUniqueValueColumns,
  );

  const minMaxValues = useMinMaxValues<typeof vehicles[0], VehicleListColumnId>(
    vehicles,
    vehicleListMinMaxValueColumns,
  );

  useEffect(() => {
    if (!urlItems) {
      setSelectedApiFilters(undefined);
      return;
    }

    const params = entries(Object.fromEntries(urlItems)).map(([key, value]) => ({
      key: key.toString() as VehicleListColumnId,
      value: value.split(','),
    }));

    const filters = params.reduce((acc, urlParam) => {
      if (urlParam.key === 'licencePlate') {
        acc.licencePlates = [...(acc.licencePlates ?? []), ...urlParam.value];
      }

      if (urlParam.key === 'make') {
        acc.vehicleMakes = [...(acc.vehicleMakes ?? []), ...urlParam.value];
      }

      if (urlParam.key === 'model') {
        acc.vehicleModels = [...(acc.vehicleModels ?? []), ...urlParam.value];
      }

      if (urlParam.key === 'driver') {
        acc.driverNames = [...(acc.driverNames ?? []), ...urlParam.value];
      }

      if (urlParam.key === 'dashboardLights') {
        acc.dashboardLightsIncludes = [
          ...(acc.dashboardLightsIncludes ?? []),
          ...(urlParam.value as DashboardLightMessageType[]),
        ];
      }

      if (urlParam.key === 'dtcPending' && urlParam.value.includes('true')) {
        acc.dtcsIncludeType = Array.from(
          new Set([...(acc.dtcsIncludeType ?? []), DtcEventType.Pending]),
        ) as DtcEventType[];
      }

      if (urlParam.key === 'dtcStored' && urlParam.value.includes('true')) {
        acc.dtcsIncludeType = Array.from(
          new Set([...(acc.dtcsIncludeType ?? []), DtcEventType.Stored]),
        ) as DtcEventType[];
      }

      if (urlParam.key === 'dtcPermanent' && urlParam.value.includes('true')) {
        acc.dtcsIncludeType = Array.from(
          new Set([...(acc.dtcsIncludeType ?? []), DtcEventType.Permanent]),
        ) as DtcEventType[];
      }

      if (urlParam.key === 'milStatus') {
        acc.milStatus = urlParam.value.includes('true') ? MilStatus.On : MilStatus.Off;
      }

      if (urlParam.key === 'batteryHealth') {
        acc.batterySohsIncludes = [...(acc.batterySohsIncludes ?? []), ...(urlParam.value as BatterySohState[])];
      }

      if (urlParam.key === 'batteryStatus') {
        acc.batteryNotificationTypesIncludes = [
          ...(acc.batteryNotificationTypesIncludes ?? []),
          ...(urlParam.value as BatteryNotificationType[]),
        ];
      }

      if (urlParam.key === 'deviceStatus') {
        acc.deviceConnectedStatusIncludes = [
          ...(acc.deviceConnectedStatusIncludes ?? []),
          ...(urlParam.value as DeviceConnectionStatus[]),
        ];
      }

      if (urlParam.key === 'distanceDriven') {
        const [min, max] = urlParam.value;
        acc.distanceDrivenFloor = parseInt(min);
        acc.distanceDrivenCeiling = parseInt(max);
      }

      if (urlParam.key === 'unassigned') {
        acc.unassigned = true;
      }

      return acc;
    }, {} as StateAggregationFilterInput);

    setSelectedApiFilters(filters);
    setPageOffset(0);
  }, [urlItems, history.location.search]);

  useEffect(() => {
    setVehicleListState({
      isInEditMode: false,
      uniqueValues,
      minMaxValues,
      onReset: () => {
        table.setAllFilters([]);
      },
    });
  }, [minMaxValues, setVehicleListState, table, uniqueValues]);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      pagination: { count_records_tfn },
    },
  } = i18nContext;

  const viewedItemRange = `${table.rows.length === 0 ? 0 : pageOffset * maxItemsPerPage + 1} - ${
    pageOffset * maxItemsPerPage + table.rows.length
  }`;

  return (
    <div className={cx('flex flex-col pt-0.5')}>
      <div className="py-1 w-full flex justify-end border-b-px border-gray-300">
        <span>{!loading && count_records_tfn(viewedItemRange, data?.count ?? 0)}</span>

        <ResetUrlParamsToolbarItem
          table={table}
          resetFilters={() => {
            setSortBy(null);
            setSelectedApiFilters(undefined);
          }}
        />
      </div>

      <FullPageTable
        table={table}
        totalCount={data?.count}
        currentPage={pageOffset}
        onPageChange={(selectedPage: number) => setPageOffset(selectedPage)}
        toggleSortBy={(id, direction) =>
          id &&
          setSortBy({
            field: vehicleColumnIdToSortFieldMap[id as Exclude<VehicleListColumnId, 'unassigned'>],
            direction: direction ? stringToSortDirection(direction) : undefined,
          })
        }
        sortedColumn={
          sortBy
            ? {
                field: sortFieldMapToVehicleColumnId[sortBy.field],
                direction: sortBy.direction ? sortDirectionToString(sortBy.direction) : undefined,
              }
            : null
        }
        hiddenColumns={hiddenColumns}
        isInEditMode={isInEditMode}
        loading={loading}
      />
    </div>
  );
};

export default withTitle(VehicleList, 'Vehicle List');
