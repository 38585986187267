import IonIcon from 'atoms/IonIcon';
import { UserListCell } from 'components/User/UserList/useUserListColumns';

const UserListFleetCell = ({ value: fleetNames }: UserListCell<(string | undefined)[]>) => {
  if (!fleetNames) return null;

  return fleetNames.length > 1 ? (
    <>
      {fleetNames.map((id) => (
        <div key={id}>{id}</div>
      ))}

      {fleetNames.length > 2 && <IonIcon name="chevronDownOutline" className="absolute right-[140px] top-1.5" />}
    </>
  ) : (
    <>
      <div className="flex h-full items-center">{fleetNames}</div>
    </>
  );
};

export default UserListFleetCell;
