import debounce from 'lodash.debounce';
import { matchSorter } from 'match-sorter';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { ColumnInstance, FilterType } from 'react-table';

import { I18nContext } from 'common/useT';
import { userSortableTextFields } from 'components/User/UserList/useUserListColumns';

const filterFunction: FilterType<any> = (rows, keys, filterValue) =>
  !filterValue ? rows : matchSorter(rows, filterValue, { keys: keys.map((key) => (row) => row.values[key]) });

const Component = ({ column: { setFilter: __unstableSetFilter, id } }: { column: ColumnInstance }) => {
  const i18nContext = useContext(I18nContext);
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFilter = useMemo(() => __unstableSetFilter, []);

  const [value, setValue] = useState('');

  const updateResults = useMemo(
    () =>
      debounce((searchTerm: string) => {
        const params = new URLSearchParams(history.location.search);

        if (searchTerm && searchTerm.length) {
          params.set(id, searchTerm);
        } else {
          params.delete(id);
        }

        history.push({ pathname: history.location.pathname, search: params.toString() });
      }, 500),
    [],
  );

  const updateValue = useCallback(
    (value: string) => {
      setValue(value);
      updateResults(value);
      userSortableTextFields.includes(id) && setFilter(value);
    },
    [setFilter],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const value = new URLSearchParams(history.location.search).get(id) ?? '';

    updateValue(value);
  }, [history.location.search, id, updateValue]);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { search_ellipsis_text },
    },
  } = i18nContext;

  return (
    <input
      value={value}
      onChange={(e) => updateValue(e.target.value)}
      placeholder={search_ellipsis_text}
      className="w-full px-1 py-0.5"
    />
  );
};

const fuzzySearchFilter = () => ({ function: filterFunction, component: Component });

export default fuzzySearchFilter;
