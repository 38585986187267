import { compareAsc, isBefore, sub } from 'date-fns';
import { isAfter, parseISO } from 'date-fns/esm';
import { useContext, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { periodStartToDate } from 'common/periodHelpers';
import { I18nContext } from 'common/useT';
import { ActivityHistoryList } from 'components/ActivityHistoryList';
import { ActivityHistoryDateFilterOption, DateFilter } from 'components/ActivityHistoryList/DateFilter';
import { EventTypeFilter } from 'components/ActivityHistoryList/EventTypeFilter';
import { activityHistoryTripIds } from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/state';
import GeofenceBreachFilter from 'components/ActivityHistoryList/GeofenceBreachFilter';
import { ListSort } from 'components/ActivityHistoryList/ListSort';
import { useCurrentVehicleId, useVehicleDetails } from 'components/Vehicle/Detail/hooks';
import {
  DeviceDiagnosticMessageType,
  GetActivityHistoryDoc,
  GetFleetGeofencesDoc,
  Period,
  TripSortType,
} from 'generated/graphql';
import { SelectOption } from 'types';
import { GeofenceBreachFilterType } from 'types/activityHistory';
import { VehicleActivityEventType, VehicleActivityHistoryEvent } from 'types/vehicleActivityHistory';
import { arrayDedupeOnKey, values } from 'utils';
import { useQ } from 'utils/apolloClient';

const maxItemsPerPage = 100;

export const ActivityHistoryPanel = () => {
  const i18nContext = useContext(I18nContext);
  const setTripIds = useSetRecoilState(activityHistoryTripIds);

  const [eventTypeFilterState, setEventTypeFilterState] = useState(values(VehicleActivityEventType));
  const [periodFilterState, setPeriodFilterState] = useState<Period | undefined>(Period.P7d);
  const [dateFromFilterState, setDateFromFilterState] = useState<Date | undefined>(sub(new Date(), { days: 7 }));
  const [dateToFilterState, setDateToFilterState] = useState<Date | undefined>(new Date());
  const [tripSortTypeState, setTripSortTypeState] = useState(TripSortType.StartTimeMostRecentFirst);
  const [geofenceBreachFilterState, setGeofenceBreachFilterState] = useState<GeofenceBreachFilterType | string>(
    GeofenceBreachFilterType.NONE,
  );
  const [currentTripPageOffsetState, setCurrentTripPageOffsetState] = useState<[number[], Date[] | undefined]>([
    [],
    undefined,
  ]);

  const resetTripOffsets = () => setCurrentTripPageOffsetState([[], undefined]);

  const [offsets, startFromNextDate] = currentTripPageOffsetState;
  const currentTripOffset = offsets.reduce((a, b) => a + b, 0);
  const dateFloor = startFromNextDate ? startFromNextDate[startFromNextDate.length - 1] : undefined;

  const vehicleId = useCurrentVehicleId();
  const {
    loading,
    error,
    data: [activityHistory] = [],
  } = useQ(GetActivityHistoryDoc, {
    variables: {
      vehicleId: vehicleId!,
      offset: currentTripOffset,
      fromDate: dateFromFilterState?.toISOString(),
      toDate: dateToFilterState?.toISOString(),
      sortType: tripSortTypeState,
    },
    fetchPolicy: 'no-cache',
  });

  const { loading: vehicleDetailsLoading, data: [vehicleDetails] = [] } = useVehicleDetails();
  const { data: fleetGeofences, loading: fleetGeofencesLoading } = useQ(GetFleetGeofencesDoc, {
    skip: !vehicleDetails?.associations.fleetIds.length,
    variables: {
      fleetId: vehicleDetails?.associations.fleetIds[0],
      withSubfleets: false,
      withParentFleets: true,
    },
  });

  useEffect(() => {
    setTripIds(activityHistory?.paginatedTrips?.map((trip) => trip.id).reverse());
  }, [activityHistory, setTripIds]);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      errors: { error_text },
    },
  } = i18nContext;

  const onDateFilterSelectPeriod = (item: ActivityHistoryDateFilterOption) => {
    if (item.value === 'custom') {
      setPeriodFilterState(undefined);
      resetTripOffsets();
      return;
    }
    const period = item.value as Period;
    setPeriodFilterState(period);
    setDateFromFilterState(periodStartToDate(period));
    setDateToFilterState(new Date());
    resetTripOffsets();
  };

  const onSelectGeofenceFilter = (item: SelectOption<GeofenceBreachFilterType | string>) => {
    setGeofenceBreachFilterState(item.value);
    resetTripOffsets();
  };

  if (error) return <div className="h-full m-2 text-lg">{error_text}</div>;

  if (
    loading ||
    vehicleDetailsLoading ||
    fleetGeofencesLoading ||
    !vehicleDetails ||
    !fleetGeofences ||
    !activityHistory
  )
    return (
      <ActivityHistoryList
        currentTripOffsetState={currentTripPageOffsetState}
        updateCurrentTripOffsetState={setCurrentTripPageOffsetState}
        showPaginationBack={false}
        showPaginationForwards={false}
        tripSortTypeState={tripSortTypeState}
        dataLoading={loading}
        filters={[
          <ListSort
            key="sort"
            currentSortType={tripSortTypeState}
            onSelect={(item) => setTripSortTypeState(item.value)}
          />,
          <DateFilter
            key="date_filter"
            currentlySelectedPeriod={periodFilterState}
            onSelectPeriod={onDateFilterSelectPeriod}
            fromDateFilter={{
              currentValue: dateFromFilterState,
              onChange: (dateFrom) => {
                setDateFromFilterState(dateFrom);
                resetTripOffsets();
              },
            }}
            toDateFilter={{
              currentValue: dateToFilterState,
              onChange: (dateTo) => {
                setDateToFilterState(dateTo);
                resetTripOffsets();
              },
            }}
          />,
          <EventTypeFilter
            key="event_type_filter"
            eventTypes={values(VehicleActivityEventType)}
            currentlySelectedEventTypes={eventTypeFilterState}
            setEventTypeItems={(items) => setEventTypeFilterState(items)}
          />,
          <GeofenceBreachFilter
            key="geofence_filter"
            disabled={!eventTypeFilterState.includes(VehicleActivityEventType.TRIP)}
            geofences={[]}
            onSelect={onSelectGeofenceFilter}
          />,
        ]}
      />
    );

  const {
    events: { battery, dashboardLights, dtc, geofenceBreaches, deviceDiagnosticMessages, impacts },
    paginatedTrips,
  } = activityHistory;

  const allEvents: VehicleActivityHistoryEvent[] = [
    ...battery.map((batteryEvent) => ({
      time: parseISO(batteryEvent.time),
      item: batteryEvent,
      type: VehicleActivityEventType.BATTERY,
    })),

    ...dashboardLights.map((dlEvent) => ({
      time: parseISO(dlEvent.time),
      item: dlEvent,
      type: VehicleActivityEventType.DASHBOARD_LIGHT,
    })),

    ...dtc.map((dtcEvent) => ({
      time: parseISO(dtcEvent.time),
      item: dtcEvent,
      type: VehicleActivityEventType.DTC,
    })),

    ...deviceDiagnosticMessages
      .filter((x) => [DeviceDiagnosticMessageType.Removed, DeviceDiagnosticMessageType.PowerLoss].includes(x.type))
      .map((msg) => ({
        time: parseISO(msg.time),
        item: msg,
        type: VehicleActivityEventType.DISCONNECTED,
      })),

    ...deviceDiagnosticMessages
      .filter((x) => [DeviceDiagnosticMessageType.Inserted, DeviceDiagnosticMessageType.PowerRestore].includes(x.type))
      .map((msg) => ({
        time: parseISO(msg.time),
        item: msg,
        type: VehicleActivityEventType.RECONNECTED,
      })),

    ...impacts.map((impactEvent) => ({
      time: parseISO(impactEvent.time),
      item: impactEvent,
      type: VehicleActivityEventType.IMPACT,
    })),

    ...paginatedTrips.map((trip) => {
      const geofenceNotifications = geofenceBreaches.filter((x) => x.tripId === trip.id);

      return {
        time: parseISO(trip.startTime),
        item: { ...trip, geofenceNotifications },
        type: VehicleActivityEventType.TRIP,
      };
    }),
  ]
    .filter((item) => {
      if (
        !eventTypeFilterState.includes(item.type) ||
        !(dateFromFilterState && isAfter(item.time, dateFromFilterState)) ||
        !(dateToFilterState && isBefore(item.time, dateToFilterState))
      ) {
        return false;
      }

      if (geofenceBreachFilterState === 'None') return true;
      if (
        geofenceBreachFilterState === GeofenceBreachFilterType.ALL &&
        item.item.__typename === 'TripRecord' &&
        item.item.geofenceNotifications.length > 0
      )
        return true;
      if (
        item.item.__typename === 'TripRecord' &&
        item.item.geofenceNotifications.length &&
        item.item.geofenceNotifications
          .map((x) => x.geofenceId.toLowerCase())
          .includes(geofenceBreachFilterState.toLowerCase())
      )
        return true;

      return false;
    })
    .sort(({ time: time1 }, { time: time2 }) =>
      [TripSortType.StartTimeMostRecentFirst, TripSortType.EndTimeMostRecentFirst].findIndex(
        (type) => type === tripSortTypeState,
      ) !== -1
        ? compareAsc(time2, time1)
        : compareAsc(time1, time2),
    );

  const startIndex = dateFloor ? allEvents.findIndex((item) => isBefore(item.time, dateFloor)) : 0;
  const remainingEvents = allEvents.slice(startIndex);
  const displayedEvents = remainingEvents.slice(0, maxItemsPerPage);
  const countTripRecordsOnCurrentPage = displayedEvents.filter(
    (item) => item.type === VehicleActivityEventType.TRIP,
  ).length;
  const nextAfterItem = displayedEvents.length ? displayedEvents[displayedEvents.length - 1].time : undefined;

  const { geofences } = vehicleDetails;
  const dedupedGeofences = arrayDedupeOnKey([...fleetGeofences, ...geofences], 'id');

  return (
    <>
      <ActivityHistoryList
        currentTripOffsetState={currentTripPageOffsetState}
        updateCurrentTripOffsetState={setCurrentTripPageOffsetState}
        showPaginationForwards={remainingEvents.length > maxItemsPerPage}
        showPaginationBack={offsets.length > 0}
        displayedEvents={displayedEvents}
        nextTripOffset={countTripRecordsOnCurrentPage}
        nextAfterItem={nextAfterItem}
        tripSortTypeState={tripSortTypeState}
        dataLoading={loading}
        filters={[
          <ListSort
            key="sort"
            currentSortType={tripSortTypeState}
            onSelect={(item) => setTripSortTypeState(item.value)}
          />,
          <DateFilter
            key="date_filter"
            currentlySelectedPeriod={periodFilterState}
            onSelectPeriod={onDateFilterSelectPeriod}
            fromDateFilter={{
              currentValue: dateFromFilterState,
              onChange: (dateFrom) => {
                setDateFromFilterState(dateFrom);
                resetTripOffsets();
              },
            }}
            toDateFilter={{
              currentValue: dateToFilterState,
              onChange: (dateTo) => {
                setDateToFilterState(dateTo);
                resetTripOffsets();
              },
            }}
          />,
          <EventTypeFilter
            key="event_type_filter"
            eventTypes={values(VehicleActivityEventType)}
            currentlySelectedEventTypes={eventTypeFilterState}
            setEventTypeItems={(items) => setEventTypeFilterState(items)}
          />,
          <GeofenceBreachFilter
            key="geofence_filter"
            disabled={!eventTypeFilterState.includes(VehicleActivityEventType.TRIP)}
            geofences={dedupedGeofences}
            onSelect={onSelectGeofenceFilter}
          />,
        ]}
      />
    </>
  );
};
