import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { ColumnInstance, FilterType } from 'react-table';

import Slider, { useSlider } from 'atoms/Slider';

const filter: FilterType<any> = (rows, keys, [min, max]: [number, number]) => {
  return rows.filter((row) => {
    const value = row.values[keys[0]];

    return value >= min && value <= max;
  });
};

const rangeFilter = ({ min, max }: { min: number; max: number }) => {
  const Component = ({ column: { setFilter: __unstableSetFilter, id } }: { column: ColumnInstance }) => {
    const history = useHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setFilter = useMemo(() => __unstableSetFilter, []);

    const urlRange = useMemo(
      () => new URLSearchParams(history.location.search).get(id)?.split(','),
      [history.location.search, id],
    );

    const { getProps, setValue, reset } = useSlider([min, max], {
      onValueChange: (range) => {
        setFilter(range);

        const params = new URLSearchParams(history.location.search);

        if (range[0] !== min || range[1] !== max) {
          params.set(id, range.join(','));
        } else {
          params.delete(id);
        }

        history.push({ pathname: history.location.pathname, search: params.toString() });
      },
    });

    useEffect(() => {
      const range = urlRange?.map(Number) ?? [min, Math.ceil(max)];

      setValue(range);
      setFilter(range);
    }, [urlRange, setFilter, setValue, reset]);

    return <Slider {...getProps()} className="w-full" />;
  };

  return {
    function: filter,
    component: Component,
  };
};

export default rangeFilter;
