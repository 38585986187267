import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { DrivingStatsHistogram } from 'components/Charts/DrivingStatsHistogram';
import EcoScoreDonutChart from 'components/Charts/EcoScoreDonutChart';
import DateFilter from 'components/Dashboard/DateFilter';
import Widget, { WidgetContext, WidgetPageType } from 'components/Dashboard/Widget';
import { selectedDriverEcoScoreTypeState } from 'components/Driver/Detail/EcoScore/state';
import { useDriverDetails } from 'components/Driver/Detail/hooks';
import { driverDetailStatisticsPeriodState } from 'components/Driver/state';
import { Period, TripEcoScore, TripEcoScoreType } from 'generated/graphql';
import { SelectOption } from 'types';

import { driverPageCollapsedWidgetsState } from '../state';
import { defaultDashboardTimePeriod } from 'common/constants';

const Filter = ({ period, setPeriod }: { period: Period; setPeriod: Dispatch<SetStateAction<Period>> }) => {
  const { data, loading, error } = useDriverDetails();

  const [selectedScoreType, setSelectedScoreType] = useRecoilState(selectedDriverEcoScoreTypeState);

  const ecoScore = data?.[0].statistics.ecoScore;

  const items = ecoScore?.map((item) => ({ value: item.type, label: item.description }));

  const selectedItem = items?.find((item) => item.value === selectedScoreType)!;

  const { getProps } = useDropdownSelect(items, {
    onSelect: (item: SelectOption) => setSelectedScoreType(item.value as TripEcoScoreType),
    initialItem: selectedItem,
    buttonIcon: <IonIcon name="chevronDownOutline" />,
  });

  if (!data || loading || error) return null;

  return (
    <div className="flex items-end">
      <DropdownSelect {...getProps()} className="!w-20" />

      <div className="mb-0.5">
        <DateFilter selectedPeriod={period} onSelect={(option) => setPeriod(option.value)} />
      </div>
    </div>
  );
};

const EcoScore = () => {
  const i18nContext = useContext(I18nContext);
  const [period, setPeriod] = useState<Period>(defaultDashboardTimePeriod);
  const drivingStatsPeriod = useRecoilValue(driverDetailStatisticsPeriodState);
  const selectedScoreType = useRecoilValue(selectedDriverEcoScoreTypeState);
  const driverPageCollapsedWidgets = useRecoilValue(driverPageCollapsedWidgetsState);
  const { widgetKey } = useContext(WidgetContext);

  const { data, loading, error } = useDriverDetails({ period });

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      domain: {
        driverDetails: {
          fields: { driverScores_text },
        },
      },
    },
  } = i18nContext;

  const [
    {
      statistics: { ecoScore },
    },
  ] = data ?? [{ statistics: {} }];

  const noDataDiv = (
    <div className="mr-0.5 mb-0.5 p-1 w-1/2 h-20 bg-white">
      {tSafe('components.Driver.Detail.EcoScore.no-data', {
        defaultValue: 'No Eco Score data for selected time period',
      })}
    </div>
  );

  const selectedData = ecoScore?.find((score) => score.type === selectedScoreType);

  const histogramData = selectedData?.histogram.map((item) => ({
    value: Math.round(item.value),
    date: item.date,
  }));

  return (
    <Widget
      title={driverScores_text}
      collapsible
      scrollable
      loading={loading}
      error={error}
      page={WidgetPageType.Driver}
    >
      <div className="flex flex-col mr-0.5 mb-0.5 p-1 w-full h-20 bg-white">
        {!driverPageCollapsedWidgets.includes(widgetKey) && (
          <div className="absolute flex flex-col top-1.5 right-1.5 mr-0 mx-auto z-20">
            <Filter period={period} setPeriod={setPeriod} />
          </div>
        )}

        {ecoScore?.length && selectedData ? (
          <>
            <div className="flex">
              <DrivingStatsHistogram
                period={drivingStatsPeriod}
                histogramData={histogramData}
                description={selectedData?.description}
                className="flex-1 h-full !min-h-20 px-1"
                yAxisRange={[0, 100]}
              />

              <EcoScoreDonutChart data={selectedData || ({} as TripEcoScore)} className="" />
            </div>
          </>
        ) : (
          noDataDiv
        )}
      </div>
    </Widget>
  );
};

export default EcoScore;
