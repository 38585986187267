import { atom } from 'recoil';

import { DriverListColumnId } from 'components/Driver/DriverList/useDriverListColumns';
import { Period } from 'generated/graphql';
import { defaultDashboardTimePeriod } from 'common/constants';

/** @package */
export const driverListState = atom({
  key: 'driverListState',
  default: {
    isInEditMode: false,
    uniqueValues: {} as Partial<Record<DriverListColumnId, NonNullable<string | null | undefined>[]>>,
    minMaxValues: {} as Partial<Record<DriverListColumnId, { min: number; max: number }>>,
    onReset: () => {},
  },
});

/** @package */
export const driverDetailStatisticsPeriodState = atom<Exclude<Period, Period.Forever>>({
  key: 'driverDetailStatisticsPeriodState',
  default: defaultDashboardTimePeriod,
});
