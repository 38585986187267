import { useMutation } from '@apollo/client';
import { PropsWithChildren } from 'react';
import { HeaderProps } from 'react-table';
import { useRecoilValue } from 'recoil';

import TableHeader from 'atoms/react-table/TableHeader';
import { driverListState } from 'components/Driver/state';
import useSettings from 'components/Settings/useSettings';
import { SetDriverListHiddenColumnsDoc } from 'generated/graphql';

interface DriverListHeaderProps extends PropsWithChildren<HeaderProps<object>> {
  text: string;
}

const DriverListHeader = ({ text, ...props }: DriverListHeaderProps) => {
  const { isInEditMode } = useRecoilValue(driverListState);
  const { hiddenColumns } = useSettings().driverListSettings;
  const [setHiddenItems] = useMutation(SetDriverListHiddenColumnsDoc);

  const onChange = (hiddenColumns: string[]) => setHiddenItems({ variables: { hiddenColumns } });

  return (
    <TableHeader
      {...props}
      text={text}
      alignment={'left'}
      isEditMode={isInEditMode}
      hiddenColumns={hiddenColumns}
      onChange={onChange}
    />
  );
};

export default DriverListHeader;
