import { useContext } from 'react';
import ReactPaginate from 'react-paginate';

import { I18nContext } from 'common/useT';

import { useFullPageTableContext } from './FullPageTable';

export interface PageSelectorProps {
  totalCount?: number;
  currentPage?: number;
  onPageChange?: (eselected: number) => void;
  useTablePagination: boolean;
  className?: string;
}

const PageSelector = ({ totalCount, currentPage, onPageChange, useTablePagination, className }: PageSelectorProps) => {
  const i18nContext = useContext(I18nContext);
  const table = useFullPageTableContext();
  const pageCount = totalCount ? Math.ceil(totalCount / 20) : 0;

  if (!table || !i18nContext) return null;

  const {
    state: { pageIndex },
    gotoPage,
  } = table;

  const handlePageChange = (selectedPage: number) => {
    onPageChange?.(selectedPage);

    if (useTablePagination) gotoPage(selectedPage);
  };

  return (
    <div className={`flex sticky left-0 ${className}`}>
      <div className="w-screen flex justify-end items-end">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          forcePage={useTablePagination ? pageIndex : currentPage ?? 0}
          onPageChange={(e) => handlePageChange(e.selected)}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          containerClassName="flex mt-2 mr-2 text-md"
          disabledClassName="opacity-0"
          activeLinkClassName="bg-gray-300"
          previousLinkClassName="p-1.5 hover:bg-gray-300"
          nextLinkClassName="p-1.5 hover:bg-gray-300"
          pageLinkClassName="p-1.5 hover:bg-gray-300"
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export default PageSelector;
