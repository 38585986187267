import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import WidgetTileGroup from 'atoms/WidgetTile/WidgetTileGroup';
import { batteryStateIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import Widget from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import {
  BatteryNotificationType,
  BatterySohState,
  BatteryState,
  GetBatteryStateEventCountDoc,
} from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';
import { useQ } from 'utils/apolloClient';

const BatteryEventCountWidget = () => {
  const i18nContext = useContext(I18nContext);

  const currentFleetId = useCurrentFleetId();
  const { loading, error, data } = useQ(GetBatteryStateEventCountDoc, {
    variables: {
      fleetId: currentFleetId,
    },
  });
  const history = useHistory();

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: {
        batteryNotificationTypeDescriptionMap,
        batterySohStateDescriptionMap,
        dashboardWidgetDescriptionMap,
        batteryStateDescriptionMap,
      },
    },
  } = i18nContext;

  const batteryStateToVehicleListFilterMap: Record<BatteryState, BatteryNotificationType | BatterySohState> = {
    [BatteryState.NotificationCritical]: BatteryNotificationType.Critical,
    [BatteryState.NotificationDrain]: BatteryNotificationType.Drain,
    [BatteryState.NotificationHibernate]: BatteryNotificationType.Hibernate,
    [BatteryState.NotificationLow]: BatteryNotificationType.Low,
    [BatteryState.NotificationOk]: BatteryNotificationType.Ok,
    [BatteryState.SohLikelyToFail]: BatterySohState.LikelyToFail,
  };

  const tiles = data
    ?.filter(({ type }) => type !== BatteryState.NotificationOk)
    .map(({ type, count }) => ({
      name: type,
      description: batteryStateDescriptionMap[type],
      value: count,
      icon: { name: batteryStateIconMap[type][0], className: batteryStateIconMap[type][1] },
      onClick: () => {
        history.push(
          `/${currentFleetId}/vehicles?${new URLSearchParams({
            [type === BatteryState.SohLikelyToFail ? 'batteryHealth' : 'batteryStatus']:
              batteryStateToVehicleListFilterMap[type],
          })}`,
        );
      },
    }));

  return (
    <Widget
      title={dashboardWidgetDescriptionMap[DashboardWidget.batteryEventCountWidget]}
      loading={loading}
      error={error}
    >
      <WidgetTileGroup tiles={tiles ?? []} valueClassName="text-error" count />
    </Widget>
  );
};

export default BatteryEventCountWidget;
