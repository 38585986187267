import { useMutation } from '@apollo/client';
import { PropsWithChildren } from 'react';
import { HeaderProps } from 'react-table';
import { useRecoilValue } from 'recoil';

import TableHeader from 'atoms/react-table/TableHeader';
import useSettings from 'components/Settings/useSettings';
import { vehicleListState } from 'components/Vehicle/state';
import { SetVehicleListHiddenColumnsDoc } from 'generated/graphql';

interface VehicleListHeaderProps extends PropsWithChildren<HeaderProps<object>> {
  text: string;
}

const VehicleListHeader = ({ text, ...props }: VehicleListHeaderProps) => {
  const { isInEditMode } = useRecoilValue(vehicleListState);
  const { hiddenColumns } = useSettings().vehicleListSettings;
  const [setHiddenItems] = useMutation(SetVehicleListHiddenColumnsDoc);

  const onChange = (hiddenColumns: string[]) => setHiddenItems({ variables: { hiddenColumns } });

  return (
    <TableHeader
      {...props}
      text={text}
      alignment={'left'}
      isEditMode={isInEditMode}
      hiddenColumns={hiddenColumns}
      onChange={onChange}
    />
  );
};

export default VehicleListHeader;
