import AddDriverToolbarItem from 'components/Driver/DriverList/DriverListToolbar/AddDriverToolbarItem';
import DriverListEditModeToolbarItem from 'components/Driver/DriverList/DriverListToolbar/DriverListEditModeToolbarItem';
import useRole from 'components/User/useRole';

const DriverListToolbar = () => {
  const { isManager } = useRole();
  return (
    <>
      {isManager && <AddDriverToolbarItem />}

      <DriverListEditModeToolbarItem />
    </>
  );
};

export default DriverListToolbar;
