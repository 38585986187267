import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import WidgetTileGroup from 'atoms/WidgetTile/WidgetTileGroup';
import { dtcIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import Widget from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { ActiveDtcVehicleCountDoc } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';
import { capitalize } from 'utils';
import { useQ } from 'utils/apolloClient';

const DtcEventCountWidget = () => {
  const i18nContext = useContext(I18nContext);
  const currentFleetId = useCurrentFleetId();
  const { loading, error, data } = useQ(ActiveDtcVehicleCountDoc, {
    variables: { fleetId: currentFleetId },
  });
  const history = useHistory();

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { dashboardWidgetDescriptionMap, dtcEventTypeDescriptionMap },
    },
  } = i18nContext;

  const tiles = data?.map(({ count, type }) => ({
    name: type,
    description: dtcEventTypeDescriptionMap[type],
    value: count,
    icon: dtcIconMap[type],
    onClick: () => {
      history.push(
        `/${currentFleetId}/vehicles?${new URLSearchParams({
          [`dtc${capitalize(type.toLocaleLowerCase())}`]: 'true',
        })}`,
      );
    },
  }));

  return (
    <Widget title={dashboardWidgetDescriptionMap[DashboardWidget.dtcEventCountWidget]} loading={loading} error={error}>
      <WidgetTileGroup tiles={tiles ?? []} valueClassName="text-error" defaultIconClassName="text-error" count />
    </Widget>
  );
};

export default DtcEventCountWidget;
