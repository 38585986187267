import { Link } from 'react-router-dom';

import { DriverListCell } from 'components/Driver/DriverList/useDriverListColumns';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';

const LicencePlateCell = ({ value, row }: DriverListCell<string | undefined | null>) => {
  const currentFleetId = useCurrentFleetId();
  const vehicleId = row.original.associations?.vehicle?.id;

  if (!vehicleId) {
    return null;
  }

  return (
    <Link to={`/${currentFleetId}/vehicles/${vehicleId}`} className="text-hover hover:underline uppercase">
      {value ?? '-----'}
    </Link>
  );
};

export default LicencePlateCell;
