import { DriverListCell } from '../useDriverListColumns';

const DriverListFleetCell = ({ value: fleetNames }: DriverListCell<(string | undefined)[]>) => {
  if (!fleetNames) return null;

  return (
    <div className="w-full">
      {fleetNames.length ? fleetNames.map((id) => <div key={id}>{id}</div>) : <div>{fleetNames}</div>}
    </div>
  );
};

export default DriverListFleetCell;
