import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { ColumnInstance, FilterType } from 'react-table';

import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { SelectOption } from 'types';

const filter: FilterType<any> = (rows, keys, selectedValues: SelectOption<string | boolean | number>[]) => {
  const tryLowerCase = (value: any) => (typeof value === 'string' ? value.toLocaleLowerCase() : value);

  if (!selectedValues?.length) {
    return rows;
  }

  const booleanStrings = ['yes', 'no', 'active', 'inactive'] as const;
  type BooleanStringsType = typeof booleanStrings[number];

  const booleanStringMap: Record<BooleanStringsType, boolean> = {
    active: true,
    yes: true,
    inactive: false,
    no: false,
  };

  const allowedValues = new Set(
    selectedValues.map(({ value }) => {
      if (typeof value === 'string') {
        if (booleanStrings.includes(value as BooleanStringsType)) {
          return booleanStringMap[value as BooleanStringsType];
        }
        return value.toLocaleLowerCase();
      }
      return value;
    }),
  );

  return rows.filter((row) => {
    const rowValue = row.values[keys[0]];

    if (Array.isArray(rowValue)) {
      return rowValue.some((item) => allowedValues.has(tryLowerCase(item)));
    }

    return allowedValues.has(tryLowerCase(rowValue));
  });
};

const multiSelectFilter = <T,>(
  items: SelectOption<T>[],
  options?: {
    searchable?: boolean;
    useTableFiltering?: boolean;
  },
) => {
  const Component = ({ column: { setFilter: __unstableSetFilter, id } }: { column: ColumnInstance }) => {
    const history = useHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setFilter = useMemo(() => __unstableSetFilter, []);

    const urlItems = useMemo(
      () =>
        new URLSearchParams(history.location.search)
          .get(id)
          ?.split(',')
          ?.map((x) => items.find((item) => item.value === x)!)
          .filter((x) => x) ?? [],
      [history.location.search, id],
    );

    const { getProps, setSelectedItems } = useDropdownSelect(items, {
      multiselect: true,
      initialItems: urlItems as typeof urlItems,

      onSelect: (items) => {
        const params = new URLSearchParams(history.location.search);

        if (items.length) {
          params.set(id, items.map((x) => x.value).join(','));
        } else {
          params.delete(id);
        }

        history.push({ pathname: history.location.pathname, search: params.toString() });
      },
      searchable: options?.searchable,
    });

    useEffect(() => {
      setSelectedItems(urlItems);
      if (options?.useTableFiltering) setFilter(urlItems);
    }, [urlItems, setFilter, setSelectedItems]);

    return <DropdownSelect {...getProps()} className="w-full text-left" />;
  };

  return {
    function: filter,
    component: Component,
  };
};

export default multiSelectFilter;
