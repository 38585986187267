import { atom } from 'recoil';

import { VehicleListColumnId } from 'components/Vehicle/VehicleList/useVehicleListColumns';

/** @package */
export const vehicleListState = atom({
  key: 'vehicleListState',
  default: {
    isInEditMode: false,
    uniqueValues: {} as Partial<Record<VehicleListColumnId, NonNullable<string | null | undefined>[]>>,
    minMaxValues: {} as Partial<Record<VehicleListColumnId, { min: number; max: number }>>,
    onReset: () => {},
  },
});

/** @package */
export const selectedVehicleIdState = atom<string | undefined>({
  key: 'selectedVehicleId',
  default: undefined,
});
