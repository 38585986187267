import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { theme } from 'twin.macro';

import { TripEcoScore } from 'generated/graphql';
import { getProgressColor } from 'utils';

export interface EcoScoreDonutChartProps {
  data: TripEcoScore;
  className?: string;
}

const EcoScoreDonutChart = ({ data, className }: EcoScoreDonutChartProps) => {
  const colour = getProgressColor(data.value);

  const pieData = [
    { value: data.value, name: data.description },
    { value: 100 - data.value, name: '' },
  ];

  return (
    <div className={`w-15 h-15 ${className}`}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            dataKey="value"
            key={data.type}
            data={pieData}
            innerRadius="85%"
            outerRadius="100%"
            startAngle={90}
            endAngle={-270}
            fill={theme`colors.gray-100`}
            isAnimationActive={true}
          >
            <Cell fill={colour} />

            <Label value={Math.round(data.value)} position="center" fontSize={theme`fontSize.6xl`} dy={-5} />

            <Label value="/ 100" position="center" fontSize={theme`fontSize.sm`} dy={25} />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EcoScoreDonutChart;
