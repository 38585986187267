import { useContext } from 'react';
import { MapContainer } from 'react-leaflet';

import { CopyToClipboardButton } from 'atoms/CopyToClipboardButton';
import { europeCenterLatlng } from 'common/constants';
import { I18nContext } from 'common/useT';
import Widget, { WidgetPageType } from 'components/Dashboard/Widget';
import { useDriverDetails } from 'components/Driver/Detail/hooks';
import { Map } from 'components/Driver/Detail/LastKnownLocation/Map';

const LastKnownLocation = () => {
  const i18nContext = useContext(I18nContext);
  const { data, loading, error } = useDriverDetails();

  if (!data || loading || error || !i18nContext) return null;

  const [{ associations }] = data;

  const {
    commonTranslations: {
      domain: {
        lastKnownLocation: {
          lat_lng_tfn,
          last_location_unknown_text,
          fields: { lat_text, lng_text },
        },
        vehicleDetails: {
          fields: { lastKnownLocations_text },
        },
      },
    },
  } = i18nContext;

  const vehicle = associations?.vehicle;

  const {
    vehicle: { id: vehicleId },
  } = vehicle ?? { vehicle: {} };

  const [{ lat, lng }] = vehicle?.lastKnownLocations.length ? vehicle.lastKnownLocations : [europeCenterLatlng];

  const mapLocation: [number, number] = [lat, lng];

  return (
    <Widget title={lastKnownLocations_text} collapsible loading={loading} error={error} page={WidgetPageType.Driver}>
      {vehicleId && vehicle?.lastKnownLocations?.length ? (
        <div className="z-0 relative flex flex-col text-md min-w-[200px] max-h-20 w-full h-full">
          <div className="z-10 m-0.5 bg-white opacity-90 rounded-8 text-sm absolute bottom-0 left-0 flex flex-row px-2 py-0.5">
            <div className="flex">
              <input readOnly className="w-20" value={lat_lng_tfn(lat, lng)} />
            </div>

            <CopyToClipboardButton className="ml-1" value={`${lat},${lng}`} />
          </div>

          <MapContainer
            center={mapLocation}
            zoom={13}
            zoomControl={false}
            scrollWheelZoom={false}
            dragging={false}
            className="z-0 w-full h-full min-h-[180px]"
            style={{ zIndex: 0 }}
          >
            <Map vehicleId={vehicleId} />
          </MapContainer>
        </div>
      ) : (
        <div className="flex flex-col pl-2">{last_location_unknown_text}</div>
      )}
    </Widget>
  );
};

export default LastKnownLocation;
