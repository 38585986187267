import { useContext, useEffect } from 'react';

import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { I18nContext } from 'common/useT';
import { SelectOption } from 'types';
import { ActivityHistoryEventType } from 'types/activityHistory';

interface Props<T extends ActivityHistoryEventType> {
  eventTypes: T[];
  currentlySelectedEventTypes: T[];
  setEventTypeItems: (items: T[]) => void;
}

export const EventTypeFilter = <T extends ActivityHistoryEventType>({
  eventTypes,
  currentlySelectedEventTypes,
  setEventTypeItems,
}: Props<T>) => {
  const i18nContext = useContext(I18nContext);

  const {
    commonTranslations: {
      general: { all_text: all, none_text: none },
      enums: { activityHistoryEventTypeDescriptionMap },
    },
  } = i18nContext ?? { commonTranslations: { general: {}, enums: {} } };

  const options = activityHistoryEventTypeDescriptionMap
    ? eventTypes.map((item) => ({
        label: activityHistoryEventTypeDescriptionMap[item],
        value: item,
      }))
    : [];

  const { getProps, setSelectedItems } = useDropdownSelect(options, {
    multiselect: true,
    initialItems: activityHistoryEventTypeDescriptionMap
      ? currentlySelectedEventTypes.map((item) => ({
          label: activityHistoryEventTypeDescriptionMap[item],
          value: item,
        }))
      : [],
    onSelect: (items: SelectOption<T>[]) => setEventTypeItems(items.map((item) => item.value)),
  });

  useEffect(() => {
    if (activityHistoryEventTypeDescriptionMap) {
      setSelectedItems(
        currentlySelectedEventTypes.map((item) => ({
          label: activityHistoryEventTypeDescriptionMap[item],
          value: item,
        })),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySelectedEventTypes, setSelectedItems, activityHistoryEventTypeDescriptionMap]);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="p-1 min-w-25 bg-gray-100 rounded-8">
      <div className="flex flex-col">
        <div className="flex items-start">
          <span className="w-full font-bold">
            {tSafe('components.ActivityHistoryList.EventTypeFilter.event-types', { defaultValue: 'Event Types' })}
          </span>

          <div className="flex mx-auto text-sm cursor-pointer">
            <div className="hover:underline" onClick={() => setEventTypeItems(eventTypes)}>
              {all}
            </div>

            <div className="mx-0.5">&#124;</div>

            <div className="hover:underline" onClick={() => setEventTypeItems([])}>
              {none}
            </div>
          </div>
        </div>

        <DropdownSelect {...getProps()} className="min-w-20 text-md" />
      </div>
    </div>
  );
};
