import { useContext } from 'react';

import Icon from 'atoms/Icon';
import Tooltip from 'atoms/Tooltip';
import { batterySohIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import { VehicleListCell } from 'components/Vehicle/VehicleList/useVehicleListColumns';
import { BatterySohState } from 'generated/graphql';
import { cx } from 'utils';

const BatteryHealthCell = ({ value }: VehicleListCell<BatterySohState | undefined | null>) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { unavailable_text: unavailableText },
      enums: { batterySohStateDescriptionMap },
    },
  } = i18nContext;

  return (
    <Tooltip text={`Battery health is ${value ? batterySohStateDescriptionMap[value] : unavailableText}`} side="right">
      <Icon
        name={value ? batterySohIconMap[value][0] : 'batteryNotEstimated'}
        className={cx('!w-3', value ? batterySohIconMap[value][1] : 'text-gray-400')}
      />
    </Tooltip>
  );
};

export default BatteryHealthCell;
