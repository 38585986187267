import { PropsWithChildren, useContext, useMemo } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue } from 'recoil';

import ColumnCollection from 'atoms/react-table/ColumnCollection';
import fuzzySearchFilter from 'atoms/react-table/tableFilters/fuzzySearchFilter';
import multiSelectFilter from 'atoms/react-table/tableFilters/multiSelectFilter';
import rangeFilter from 'atoms/react-table/tableFilters/rangeFilter';
import { tripStatisticConversionRatios } from 'common/uomConverters';
import { I18nContext } from 'common/useT';
import DeviceStatusCell from 'components/Driver/DriverList/cells/DeviceStatusCell';
import DriverListFleetCell from 'components/Driver/DriverList/cells/DriverListFleetCell';
import DriverNameCell from 'components/Driver/DriverList/cells/DriverNameCell';
import EcoScoreCell from 'components/Driver/DriverList/cells/EcoScoreCell';
import LicencePlateCell from 'components/Driver/DriverList/cells/LicencePlateCell';
import DriverListHeader from 'components/Driver/DriverList/DriverListHeader';
import useSettings from 'components/Settings/useSettings';
import useUser from 'components/User/useUser';
import { DeviceConnectionStatus, GetAggregatedDriverListDoc, TripStatisticType } from 'generated/graphql';
import { Result } from 'types';
import { entries } from 'utils';

import { driverListState } from '../state';

type DriverListItem = Result<typeof GetAggregatedDriverListDoc>['data'][0];

export type DriverListCell<T> = PropsWithChildren<CellProps<DriverListItem, T>>;

export type DriverListColumnId =
  | 'driver'
  | 'licencePlate'
  | 'deviceStatus'
  | 'fleet'
  | 'impactStatus'
  | 'ecoScore'
  | 'distanceDriven'
  | 'tripCount'
  | 'drivingTime'
  | 'idleTime'
  | 'longIdlingEventCount'
  | 'impactStatus'
  | 'fuelConsumption'
  | 'fuelEfficiency'
  | 'unassigned';

export const driverListUniqueValueColumns: [DriverListColumnId, (x: DriverListItem) => string | null | undefined][] =
  [];

export const driverListMinMaxValueColumns: [DriverListColumnId, (x: DriverListItem) => number | null | undefined][] =
  [];

export const useDriverListColumns = () => {
  const i18nContext = useContext(I18nContext);
  const { minMaxValues } = useRecoilValue(driverListState);
  const { accessibleFleets } = useUser();

  const fleetList = [...accessibleFleets]
    .map((fleet) => ({
      label: fleet.name,
      value: fleet.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const { distanceInMiles, volumeInGallons, idleTimeAsPercentage } = useSettings();
  const conversionRatioMap = tripStatisticConversionRatios({ distanceInMiles, volumeInGallons });

  const columns = useMemo(
    () => {
      if (i18nContext) {
        const {
          commonTranslations: {
            general: { some_text, none_text, unassigned_text },
            domain: {
              driver: { driver_text, longIdling_text },
              vehicle: {
                fields: { licencePlate_text },
              },
              device: {
                fields: { device_connection_status_text },
              },
              fleet: { fleet_text },
              impact: { impact_events_text },
            },
            enums: { deviceConnectionStatusDescriptionMap, tripStatisticTypeDescriptionMap },
          },
        } = i18nContext;
        const collection = new ColumnCollection<DriverListItem, DriverListColumnId>(DriverListHeader);

        collection.add('driver', driver_text, (x) => x.user?.name, {
          component: DriverNameCell,
          filter: fuzzySearchFilter(),
        });

        collection.add('licencePlate', licencePlate_text, (x) => x.associations?.vehicle?.licencePlate, {
          component: LicencePlateCell,
          filter: fuzzySearchFilter(),
        });

        collection.add(
          'deviceStatus',
          device_connection_status_text,
          (x) => x.associations?.device?.connectionStatus ?? DeviceConnectionStatus.Disconnected,
          {
            component: DeviceStatusCell,
            containerClassName: 'w-full flex-center',
            sortType: 'basic',
            sortInverted: true,
            filter: multiSelectFilter(
              entries(deviceConnectionStatusDescriptionMap).map(([type, description]) => ({
                label: description,
                value: type,
              })),
            ),
            width: 140,
          },
        );

        collection.add('fleet', fleet_text, (x) => x.user!.fleets.map((x) => x.name ?? undefined), {
          component: DriverListFleetCell,
          filter: multiSelectFilter(fleetList),
          disableSortBy: true,
        });

        collection.add('impactStatus', impact_events_text, (x) => x.statistics.numImpacts ?? 0, {
          containerClassName: 'w-full flex-center',
          filter: multiSelectFilter([
            { label: some_text, value: 'true' },
            { label: none_text, value: 'false' },
          ]),
        });

        collection.add(
          'ecoScore',
          tripStatisticTypeDescriptionMap[TripStatisticType.EcoScore],
          (x) => x.statistics.ecoScore ?? 0,
          { containerClassName: 'w-full flex-center', component: EcoScoreCell },
        );

        collection.add(
          'distanceDriven',
          tripStatisticTypeDescriptionMap[TripStatisticType.TotalDistanceDriven],
          (x) => {
            const distance = x.statistics.totalDistanceDriven ?? 0;
            return (distance * conversionRatioMap[TripStatisticType.TotalDistanceDriven]).toFixed(0);
          },
          {
            containerClassName: 'w-full flex-center',
            filter: rangeFilter({ min: 0, max: 1200000 }),

            width: 180,
          },
        );

        collection.add(
          'tripCount',
          tripStatisticTypeDescriptionMap[TripStatisticType.TripCount],
          (x) => x.statistics.numTrips ?? 0,
          { containerClassName: 'w-full flex-center', width: 140 },
        );

        collection.add(
          'drivingTime',
          tripStatisticTypeDescriptionMap[TripStatisticType.TotalTimeDriven],
          (x) => x.statistics.totalTimeDriven?.toFixed(0) ?? 0,
          { containerClassName: 'w-full flex-center', width: 160 },
        );

        collection.add('longIdlingEventCount', longIdling_text, (x) => x.statistics.numLongIdlingEvents ?? 0, {
          containerClassName: 'w-full flex-center',
          filter: multiSelectFilter([
            { label: some_text, value: 'true' },
            { label: none_text, value: 'false' },
          ]),
          width: 140,
        });

        collection.add(
          'idleTime',
          idleTimeAsPercentage
            ? tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimePct]
            : tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimeHrs],
          (x) =>
            idleTimeAsPercentage
              ? x.statistics.idleTimePct?.toFixed(0) ?? 0
              : x.statistics.idleTimeHrs?.toFixed(0) ?? 0,
          { containerClassName: 'w-full flex-center', width: 120 },
        );

        collection.add(
          'fuelConsumption',
          tripStatisticTypeDescriptionMap[TripStatisticType.FuelConsumption],
          (x) => {
            const volume = x.statistics.fuelConsumption ?? 0;
            return (volume * conversionRatioMap[TripStatisticType.FuelConsumption]).toFixed(0);
          },
          { containerClassName: 'w-full flex-center', width: 150 },
        );

        collection.add(
          'fuelEfficiency',
          tripStatisticTypeDescriptionMap[TripStatisticType.FuelEfficiency],
          (x) => {
            const volume = x.statistics.fuelEfficiency ?? 0;
            return (volume * conversionRatioMap[TripStatisticType.FuelEfficiency]).toFixed(0);
          },
          { containerClassName: 'w-full flex-center', width: 160, disableSortBy: true },
        );

        collection.add('unassigned', unassigned_text, (_) => null, {
          containerClassName: 'w-full flex-center',
          width: 160,
          disableSortBy: true,
          filter: multiSelectFilter([{ label: 'No Vehicle', value: 'Vehicle' }]),
        });

        return collection.toArray();
      } else {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [distanceInMiles, minMaxValues.distanceDriven, idleTimeAsPercentage, volumeInGallons, i18nContext],
  );

  return columns;
};
