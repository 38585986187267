import {
  BatteryNotificationMessage,
  DashboardLightEvent,
  DeviceDiagnosticMessage,
  DtcEventMessage,
  GeofenceNotification,
  ImpactEventNotificationMessage,
  TripRecord,
} from 'generated/graphql';

export enum VehicleActivityEventType {
  BATTERY = 'battery',
  DASHBOARD_LIGHT = 'dashboard_light',
  DISCONNECTED = 'disconnected',
  DTC = 'dtc',
  RECONNECTED = 'reconnected',
  IMPACT = 'impact',
  TRIP = 'trip',
}

export type VehicleActivityEventItem =
  | (Partial<TripRecord> & { geofenceNotifications: Partial<GeofenceNotification>[] })
  | Partial<DtcEventMessage>
  | Partial<DashboardLightEvent>
  | Partial<ImpactEventNotificationMessage>
  | Partial<BatteryNotificationMessage>
  | Partial<DeviceDiagnosticMessage>;

export interface VehicleActivityHistoryEvent {
  time: Date;
  item: VehicleActivityEventItem;
  type: VehicleActivityEventType;
}
