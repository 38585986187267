import NoDataResponse, { ResponseType, SeverityLevel } from 'atoms/NoDataResponse';
import { CenteredSpinner } from 'atoms/Spinner';
import { useT } from 'common/useT';
import DriverList from 'components/Driver/DriverList';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { AggregateDriverList, GetFleetIdlePerformanceDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

const IdlePerformanceList = () => {
  const { tSafe } = useT();

  const {
    loading,
    error,
    data: driverListWithCount,
  } = useQ(GetFleetIdlePerformanceDoc, {
    variables: {
      fleetId: useCurrentFleetId(),
    },
  });

  if (loading) return <CenteredSpinner />;
  if (error) return <NoDataResponse level={SeverityLevel.Error} reason={ResponseType.FailedToFetch} />;

  return <DriverList driverData={driverListWithCount as AggregateDriverList} />;
};

export default IdlePerformanceList;
