import { useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import IonIcon from 'atoms/IonIcon';
import NoDataResponse, { ResponseType } from 'atoms/NoDataResponse';
import Tooltip from 'atoms/Tooltip';
import { defaultDashboardTimePeriod } from 'common/constants';
import { I18nContext } from 'common/useT';
import { DrivingStatsHistogram } from 'components/Charts/DrivingStatsHistogram';
import EcoScoreDonutChart from 'components/Charts/EcoScoreDonutChart';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { GetFleetEcoScoresDoc, Period, TripEcoScoreType } from 'generated/graphql';
import { SelectOption } from 'types';
import { DashboardWidget } from 'types/widgets';
import { useQ } from 'utils/apolloClient';

import Widget from '../Widget';
import { selectedEcoScoreTypeState } from './state';

interface DropdownSelectWrapperProps {
  items: {
    value: TripEcoScoreType;
    label: string;
  }[];
  initialItem: {
    value: TripEcoScoreType;
    label: string;
  };
}
const DropdownSelectWrapper = ({ items, initialItem }: DropdownSelectWrapperProps) => {
  const setSelectedScoreType = useSetRecoilState(selectedEcoScoreTypeState);
  const { getProps } = useDropdownSelect(items, {
    onSelect: (item: SelectOption) => setSelectedScoreType(item.value as TripEcoScoreType),
    initialItem,
    buttonIcon: <IonIcon name="chevronDownOutline" />,
  });
  return <DropdownSelect {...getProps()} className="!w-20" />;
};

const Filter = () => {
  const i18nContext = useContext(I18nContext);
  const { loading, error, data } = useQ(GetFleetEcoScoresDoc, {
    variables: {
      period: defaultDashboardTimePeriod,
      fleetId: useCurrentFleetId(),
    },
  });
  const selectedScoreType = useRecoilValue(selectedEcoScoreTypeState);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { n_day_period_text },
    },
  } = i18nContext;

  const items = data?.map((item) => ({ value: item.type, label: item.description }));

  const selectedItem = items?.find((item) => item.value === selectedScoreType)!;

  if (!items || loading || error || !data!.length) return null;

  return (
    <Tooltip text={n_day_period_text(30)}>
      <DropdownSelectWrapper items={items} initialItem={selectedItem} />
    </Tooltip>
  );
};

const EcoScore = () => {
  const i18nContext = useContext(I18nContext);
  const { loading, error, data } = useQ(GetFleetEcoScoresDoc, {
    variables: {
      period: defaultDashboardTimePeriod,
      fleetId: useCurrentFleetId(),
    },
  });

  const selectedScoreType = useRecoilValue(selectedEcoScoreTypeState);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { dashboardWidgetDescriptionMap },
    },
  } = i18nContext;

  const selectedData = data?.find((score) => score.type === selectedScoreType);

  return (
    <Widget
      title={dashboardWidgetDescriptionMap[DashboardWidget.ecoScoreWidget]}
      filterComponent={<Filter />}
      loading={loading}
      error={error}
    >
      {selectedData ? (
        <div className="flex items-center p-2 w-full h-full space-x-4">
          <DrivingStatsHistogram
            period={defaultDashboardTimePeriod}
            histogramData={selectedData.histogram.map((item) => ({
              value: Math.round(item.value),
              date: item.date,
            }))}
            description={selectedData.description}
            className="flex-1 h-full"
            yAxisRange={[0, 100]}
          />

          <EcoScoreDonutChart data={selectedData} className="" />
        </div>
      ) : (
        <NoDataResponse reason={ResponseType.NoFleetData} />
      )}
    </Widget>
  );
};

export default EcoScore;
