import {
  DeviceDiagnosticMessage,
  GeofenceNotification,
  ImpactEventNotificationMessage,
  TripRecord,
} from 'generated/graphql';

export enum DriverActivityEventType {
  TRIP = 'trip',
  IMPACT = 'impact',
  DISCONNECTED = 'disconnected',
  RECONNECTED = 'reconnected',
  LONG_IDLING = 'longIdling',
}

export type DriverActivityEventItem =
  | (Partial<TripRecord> & { geofenceNotifications: Partial<GeofenceNotification>[] })
  | Partial<ImpactEventNotificationMessage>
  | Partial<DeviceDiagnosticMessage>;

export interface DriverActivityHistoryEvent {
  time: Date;
  item: DriverActivityEventItem;
  type: DriverActivityEventType;
}
