import { format, parseISO } from 'date-fns';
import { useContext } from 'react';

import DeviceStatusIcon from 'atoms/DeviceStatusIcon';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { DeviceConnectionStatus } from 'generated/graphql';

import { VehicleListCell } from '../useVehicleListColumns';

const DeviceStatusCell = ({
  value,
  row: {
    original: {
      associations: { device },
    },
  },
}: VehicleListCell<DeviceConnectionStatus>) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { deviceConnectionStatusDescriptionMap },
      domain: {
        device: {
          fields: { latestConnectionDate_text, latestHeartbeat_text },
        },
      },
    },
  } = i18nContext;

  return (
    <span className="w-full flex-center">
      <Tooltip
        text={
          <div>
            <div>{deviceConnectionStatusDescriptionMap[value]}</div>

            <div>
              {value === DeviceConnectionStatus.NotReporting && device?.latestHeartbeat
                ? `${latestHeartbeat_text}: ${device?.latestHeartbeat}`
                : device?.latestConnectionDate &&
                  `${latestConnectionDate_text}: ${format(
                    parseISO(device?.latestConnectionDate),
                    'do MMM y - h:mm a',
                  )}`}
            </div>
          </div>
        }
        side="right"
      >
        <DeviceStatusIcon connectionStatus={value} />
      </Tooltip>
    </span>
  );
};

export default DeviceStatusCell;
