import { useContext } from 'react';

import { I18nContext } from 'common/useT';

export interface ActivityHistoryPaginationProps {
  currentTripOffsetState: [number[], Date[] | undefined];
  updateCurrentTripOffsetState: (currState: [number[], Date[] | undefined]) => void;
  nextTripOffset: number;
  showPaginationForwards: boolean;
  showPaginationBack: boolean;
  nextAfterItem?: Date;
  onNext?: () => void;
  onPrev?: () => void;
}

export const Pagination = ({
  currentTripOffsetState,
  updateCurrentTripOffsetState,
  nextTripOffset,
  showPaginationForwards,
  showPaginationBack,
  nextAfterItem,
  onNext,
  onPrev,
}: ActivityHistoryPaginationProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      pagination: { next_page_text, previous_page_text },
    },
  } = i18nContext;

  return (
    <div className="m-1 w-full justify-between relative">
      {showPaginationBack ? (
        <div
          onClick={() => {
            const [offsets, dates] = currentTripOffsetState;
            const newOffsets = [...offsets.slice(0, -1)];
            const newDates = dates ? [...dates.slice(0, -1)] : undefined;

            updateCurrentTripOffsetState([newOffsets, newDates]);

            onPrev?.();
          }}
          className="absolute left-0 font-bold cursor-pointer ui-button bg-white p-2"
        >
          &lt; {previous_page_text}
        </div>
      ) : null}

      {showPaginationForwards ? (
        <div
          onClick={() => {
            if (nextAfterItem) {
              const [offsets, dates] = currentTripOffsetState;
              const newOffsets = [...offsets, nextTripOffset];
              const newDates = dates ? [...dates, nextAfterItem] : [nextAfterItem];

              updateCurrentTripOffsetState([newOffsets, newDates]);

              onNext?.();
            }
          }}
          className="absolute font-bold right-0 cursor-pointer ui-button bg-white p-2"
        >
          {next_page_text} &gt;
        </div>
      ) : null}
    </div>
  );
};
