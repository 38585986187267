import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { useRecoilState } from 'recoil';

import FullPageTable, { sortDirectionToString, stringToSortDirection } from 'atoms/react-table/FullPageTable';
import useColumnUniqueValues from 'atoms/react-table/tableFilters/useColumnUniqueValues';
import useMinMaxValues from 'atoms/react-table/tableFilters/useMinMaxValues';
import { I18nContext } from 'common/useT';
import {
  useDriverListColumns,
  DriverListColumnId,
  driverListUniqueValueColumns,
  driverListMinMaxValueColumns,
} from 'components/Driver/DriverList/useDriverListColumns';
import { driverListState } from 'components/Driver/state';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useSettings from 'components/Settings/useSettings';
import ResetUrlParamsToolbarItem from 'components/Toolbar/ResetUrlParamsToolbarItem';
import {
  AggregateDriverList,
  AggregateSortByInput,
  DeviceConnectionStatus,
  GetAggregatedDriverListDoc,
  ListSortDirection,
  StateAggregationFilterInput,
  StateAggregatorSortField,
} from 'generated/graphql';
import { entries, reverseRecord, withTitle } from 'utils';
import { useQ } from 'utils/apolloClient';

const driverListColumnIdToSortFieldMap: Omit<Record<DriverListColumnId, StateAggregatorSortField>, 'unassigned'> = {
  deviceStatus: StateAggregatorSortField.DeviceStatus,
  distanceDriven: StateAggregatorSortField.TotalDistanceDriven,
  driver: StateAggregatorSortField.DriverName,
  licencePlate: StateAggregatorSortField.VehicleLicencePlate,
  drivingTime: StateAggregatorSortField.TotalTripDuration,
  ecoScore: StateAggregatorSortField.EcoScore,
  fleet: StateAggregatorSortField.Fleet,
  fuelConsumption: StateAggregatorSortField.FuelConsumption,
  fuelEfficiency: StateAggregatorSortField.FuelEfficiency,
  idleTime: StateAggregatorSortField.TotalIdleTime,
  longIdlingEventCount: StateAggregatorSortField.NumDriverLongIdlingEvents,
  impactStatus: StateAggregatorSortField.NumDriverImpacts,
  tripCount: StateAggregatorSortField.NumTrips,
};

const sortFieldMapToVehicleColumnId = reverseRecord(driverListColumnIdToSortFieldMap);

const DriverList = ({ driverData }: { driverData?: AggregateDriverList }) => {
  const i18nContext = useContext(I18nContext);
  const [pageOffset, setPageOffset] = useState(0);
  const [sortBy, setSortBy] = useState<AggregateSortByInput | null>({
    field: StateAggregatorSortField.DeviceStatus,
    direction: ListSortDirection.Asc,
  });
  const maxItemsPerPage = 20;
  const [selectedApiFilters, setSelectedApiFilters] = useState<StateAggregationFilterInput>();
  const { data, loading, refetch } = useQ(GetAggregatedDriverListDoc, {
    variables: {
      fleetId: useCurrentFleetId(),
      filters: selectedApiFilters,
      sortBy,
      limit: maxItemsPerPage,
      offset: pageOffset,
    },
    notifyOnNetworkStatusChange: true,
    skip: !!driverData,
  });

  const [{ isInEditMode }, setDriverListState] = useRecoilState(driverListState);
  const { hiddenColumns } = useSettings().driverListSettings;

  const history = useHistory();
  const urlItems = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);

  const drivers = useMemo(() => (driverData ? driverData.data : data?.data ?? []), [driverData, data]);

  const table = useTable(
    {
      columns: useDriverListColumns(),
      data: drivers,
      initialState: {
        pageSize: maxItemsPerPage,
        hiddenColumns,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const uniqueValues = useColumnUniqueValues<typeof drivers[0], DriverListColumnId>(
    drivers,
    driverListUniqueValueColumns,
  );

  const minMaxValues = useMinMaxValues<typeof drivers[0], DriverListColumnId>(drivers, driverListMinMaxValueColumns);

  useEffect(() => {
    if (!urlItems) {
      setSelectedApiFilters(undefined);
      return;
    }

    const params = entries(Object.fromEntries(urlItems)).map(([key, value]) => ({
      key: key.toString() as DriverListColumnId,
      value: value.split(','),
    }));

    const filters = params.reduce((acc, urlParam) => {
      if (urlParam.key === 'driver') {
        acc.driverNames = [...(acc.driverNames ?? []), ...urlParam.value];
      }

      if (urlParam.key === 'licencePlate') {
        acc.licencePlates = [...(acc.licencePlates ?? []), ...urlParam.value];
      }

      if (urlParam.key === 'deviceStatus') {
        acc.deviceConnectedStatusIncludes = [
          ...(acc.deviceConnectedStatusIncludes ?? []),
          ...(urlParam.value as DeviceConnectionStatus[]),
        ];
      }

      if (urlParam.key === 'fleet') {
        acc.fleetIds = [...(acc.fleetIds ?? []), ...urlParam.value];
      }

      if (urlParam.key === 'impactStatus') {
        acc.driverHasImpacts = true;
      }

      if (urlParam.key === 'longIdlingEventCount') {
        acc.driverHasLongIdlingEvents = true;
      }

      if (urlParam.key === 'distanceDriven') {
        const [min, max] = urlParam.value;
        acc.distanceDrivenFloor = parseInt(min);
        acc.distanceDrivenCeiling = parseInt(max);
      }

      if (urlParam.key === 'unassigned') {
        acc.unassigned = true;
      }

      return acc;
    }, {} as StateAggregationFilterInput);

    setPageOffset(0);
    setSelectedApiFilters(filters);
  }, [urlItems, history.location.search]);

  useEffect(() => {
    setDriverListState({
      isInEditMode: false,
      uniqueValues,
      minMaxValues,
      onReset: () => {
        table.setAllFilters([]);
      },
    });
  }, [minMaxValues, setDriverListState, table, uniqueValues]);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      pagination: { count_records_tfn },
    },
  } = i18nContext;

  const viewedItemRange = `${table.rows.length === 0 ? 0 : pageOffset + 1} - ${pageOffset + table.rows.length}`;

  return (
    <div className="flex flex-col">
      {!loading && (
        <div className="py-1 w-full flex justify-end border-b-px border-gray-300">
          <span>
            {!loading && count_records_tfn(viewedItemRange, driverData ? driverData.count : data?.count ?? 0)}
          </span>

          <ResetUrlParamsToolbarItem
            table={table}
            resetFilters={() => {
              setSortBy(null);
              setSelectedApiFilters(undefined);
            }}
          />
        </div>
      )}

      <FullPageTable
        table={table}
        totalCount={data?.count}
        currentPage={pageOffset}
        onPageChange={(selectedPage: number) => setPageOffset(selectedPage * maxItemsPerPage)}
        toggleSortBy={(id, direction) =>
          id &&
          setSortBy({
            field: driverListColumnIdToSortFieldMap[id as Exclude<DriverListColumnId, 'unassigned'>],
            direction: direction ? stringToSortDirection(direction) : undefined,
          })
        }
        sortedColumn={
          sortBy
            ? {
                field: sortFieldMapToVehicleColumnId[sortBy.field],
                direction: sortBy.direction ? sortDirectionToString(sortBy.direction) : undefined,
              }
            : null
        }
        hiddenColumns={hiddenColumns}
        isInEditMode={isInEditMode}
        loading={loading}
      />
    </div>
  );
};

export default withTitle(DriverList, 'Driver List');
