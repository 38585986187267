import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { useRecoilState } from 'recoil';

import FullPageTable, { sortDirectionToString, stringToSortDirection } from 'atoms/react-table/FullPageTable';
import useColumnUniqueValues from 'atoms/react-table/tableFilters/useColumnUniqueValues';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useSettings from 'components/Settings/useSettings';
import ResetUrlParamsToolbarItem from 'components/Toolbar/ResetUrlParamsToolbarItem';
import { userListState } from 'components/User/UserList/state';
import {
  useUserListColumns,
  UserListColumnId,
  userListUniqueValueColumns,
} from 'components/User/UserList/useUserListColumns';
import { GetUsersDoc, ListSortDirection } from 'generated/graphql';
import { withTitle } from 'utils';
import { useQ } from 'utils/apolloClient';

type UserListSortField = 'name' | 'role' | 'driver' | 'active' | 'email';

type UserListSortFieldInput = {
  field: UserListSortField;
  direction?: ListSortDirection;
};

const UserList = () => {
  const i18nContext = useContext(I18nContext);
  const [pageOffset, setPageOffset] = useState(0);
  const [sortBy, setSortBy] = useState<UserListSortFieldInput | null>(null);
  const { data, loading } = useQ(GetUsersDoc, {
    variables: { fleetId: useCurrentFleetId() },
    fetchPolicy: 'cache-first',
  });
  const maxItemsPerPage = 20;

  const [{ isInEditMode }, setUserListState] = useRecoilState(userListState);
  const { hiddenColumns } = useSettings().userListSettings;

  const history = useHistory();
  const urlItems = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);

  const users = useMemo(() => data ?? [], [data]);

  const table = useTable(
    {
      columns: useUserListColumns(),
      data: users,
      initialState: {
        pageSize: 20,
        hiddenColumns,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const uniqueValues = useColumnUniqueValues<typeof users[0], UserListColumnId>(users, userListUniqueValueColumns);

  useEffect(() => {
    setUserListState({
      isInEditMode: false,
      uniqueValues,
      onReset: () => {
        table.setAllFilters([]);
      },
    });
  }, [setUserListState, table, uniqueValues]);

  useEffect(() => {
    setPageOffset(0);
  }, [urlItems, history.location.search]);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      pagination: { count_records_tfn },
    },
  } = i18nContext;

  const viewedItemRange = `${table.rows.length === 0 ? 0 : pageOffset + 1} - ${
    table.rows.length < pageOffset + maxItemsPerPage ? table.rows.length : pageOffset + maxItemsPerPage
  }`;

  return (
    <div>
      {!loading && (
        <div className="py-1 w-full flex justify-end border-b-px border-gray-300">
          <span>{!loading && count_records_tfn(viewedItemRange, table.rows.length ?? 0)}</span>

          <ResetUrlParamsToolbarItem
            resetFilters={() => {
              setSortBy(null);
            }}
            table={table}
          />
        </div>
      )}

      <FullPageTable
        totalCount={table.rows.length}
        currentPage={pageOffset}
        onPageChange={(selectedPage: number) => setPageOffset(selectedPage * maxItemsPerPage)}
        toggleSortBy={(id, direction) =>
          id &&
          setSortBy({
            field: id as UserListSortField,
            direction: direction ? stringToSortDirection(direction) : undefined,
          })
        }
        sortedColumn={
          sortBy
            ? {
                field: sortBy.field,
                direction: sortBy.direction ? sortDirectionToString(sortBy.direction) : undefined,
              }
            : null
        }
        table={table}
        hiddenColumns={hiddenColumns}
        isInEditMode={isInEditMode}
        loading={loading}
        useTablePagination
      />
    </div>
  );
};

export default withTitle(UserList, 'User List');
