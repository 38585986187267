import { useContext } from 'react';

import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { I18nContext } from 'common/useT';
import { TripSortType } from 'generated/graphql';
import { SelectOption } from 'types';

interface ListSortProps {
  currentSortType: TripSortType;
  onSelect: (item: SelectOption<TripSortType>) => void;
}

export const ListSort = ({ currentSortType, onSelect }: ListSortProps) => {
  const i18nContext = useContext(I18nContext);

  const {
    commonTranslations: {
      general: { sort_by_text },
      enums: { tripSortTypeDescriptionMap },
    },
  } = i18nContext ?? {
    commonTranslations: {
      general: {},
      enums: {},
    },
  };
  const items = tripSortTypeDescriptionMap
    ? [TripSortType.StartTimeMostRecentFirst, TripSortType.StartTimeOldestFirst].map((item) => ({
        label: tripSortTypeDescriptionMap[item],
        value: item,
      }))
    : [];

  const { getProps } = useDropdownSelect(items, {
    initialItem: items.find((item) => item.value === currentSortType) ?? items[0],
    onSelect,
  });

  return (
    <div className="flex items-center px-1 min-w-25 bg-gray-100 rounded-8">
      <div className="flex flex-col">
        <span className="font-bold">{sort_by_text}</span>

        <DropdownSelect {...getProps()} className="min-w-20 text-md" />
      </div>
    </div>
  );
};
