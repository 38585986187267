import { useContext } from 'react';

import { I18nContext } from 'common/useT';
import VehicleList from 'components/Vehicle/VehicleList';

const VehiclesPage = () => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="relative px-2">
      <h1 className="absolute top-1.5 text-md font-bold">
        {tSafe('components.Vehicle.VehiclesPage.title', { defaultValue: "Selected fleet's vehicles" })}
      </h1>

      <VehicleList />
    </div>
  );
};

export default VehiclesPage;
