import IonIcon from 'atoms/IonIcon';
import { UserListCell } from 'components/User/UserList/useUserListColumns';

const UserListActiveCell = ({ value: active }: UserListCell<boolean>) => {
  return active ? (
    <IonIcon name="checkmarkCircleOutline" className="text-success text-xl" />
  ) : (
    <IonIcon name="closeOutline" className="text-error text-xl" />
  );
};

export default UserListActiveCell;
