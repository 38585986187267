import CountWithDetails from 'atoms/react-table/CountWithDetails';
import { DriverListCell } from 'components/Driver/DriverList/useDriverListColumns';
import { getProgressColor } from 'utils';

const EcoScoreCell = ({ value }: DriverListCell<number | undefined | null>) => {
  if (value === undefined || value === null) {
    return null;
  }

  return (
    <CountWithDetails label="Eco Scores" count={Math.round(value)} bgColor={getProgressColor(value)}>
      <div className="flex flex-col gap-2">
        {/* {row.original.statistics.ecoScore.map(({ type, value, description }) =>
          type === TripEcoScoreType.OverallEcoScore ? null : (
            <div className="flex gap-1 items-center" key={type}>
              <span
                className="w-3 h-3 text-white text-md rounded-4 flex-center"
                style={{ backgroundColor: getProgressColor(value) }}
              >
                {Math.round(value)}
              </span>

              <span className="text-sm">{description}</span>
            </div>
          ),
        )} */}
      </div>
    </CountWithDetails>
  );
};

export default EcoScoreCell;
