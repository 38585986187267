import { format } from 'date-fns';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { dashboardLightsIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import {
  DateTimeColumn,
  DefaultAdditionalColumn,
  EventDetailContainer,
  EventTypeColumn,
  IconColumn,
} from 'components/ActivityHistoryList/styledComponents';
import { DashboardLightEvent } from 'generated/graphql';
import { cx } from 'utils';

interface DashboardLightEventRowItemProps {
  event: Partial<DashboardLightEvent>;
  time: Date;
}

export const DashboardLightEventRowItem = ({ event, time }: DashboardLightEventRowItemProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: {
        dashboardLightStatusDescriptionMap,
        confidenceLevelDescriptionMap,
        dashboardLightMessageTypeDescriptionMap,
      },
      domain: {
        dashboardLight: { dashboard_light_text },
      },
    },
  } = i18nContext;

  const { status, confidenceLevel, description, type } = event;

  return (
    <EventDetailContainer>
      <DateTimeColumn>{format(time, 'iii PPp')}</DateTimeColumn>

      <EventTypeColumn>{dashboard_light_text}</EventTypeColumn>

      <IconColumn>
        <Icon
          name={dashboardLightsIconMap[event.type!]}
          className={cx('!w-3', status === 'ON' ? 'text-error' : 'text-success')}
        />
      </IconColumn>

      <DefaultAdditionalColumn>
        {description ?? (type ? dashboardLightMessageTypeDescriptionMap[type] : null)}
      </DefaultAdditionalColumn>

      <DefaultAdditionalColumn>{status ? dashboardLightStatusDescriptionMap[status] : null}</DefaultAdditionalColumn>

      <DefaultAdditionalColumn>
        {confidenceLevel ? confidenceLevelDescriptionMap[confidenceLevel] : null}
      </DefaultAdditionalColumn>
    </EventDetailContainer>
  );
};
