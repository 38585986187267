import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { I18nContext } from 'common/useT';
import DriverList from 'components/Driver/DriverList';

import IdlePerformanceList from './IdlePerformanceList';

export enum ListFilterKeys {
  DEFAULT = 'default',
  IDLE_PERFORMANCE = 'idle-performance',
}

interface DriverListTtype {
  title: string;
  list: JSX.Element;
}

const DriversPage = () => {
  const history = useHistory();
  const filter = new URLSearchParams(history.location.search).get('filter') ?? 'default';

  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;
  const driverListMap: Record<string, DriverListTtype> = {
    [ListFilterKeys.DEFAULT]: {
      title: tSafe('components.Driver.DriversPage.title', { defaultValue: "Selected fleet's drivers" }),
      list: <DriverList />,
    },
    [ListFilterKeys.IDLE_PERFORMANCE]: {
      title: tSafe('components.Driver.DriversPage.idle-performance', {
        defaultValue: 'Bottom 10% idling performance for your fleet',
      }),
      list: <IdlePerformanceList />,
    },
  };

  return (
    <div className="relative px-2">
      <h1 className="absolute top-1.5 text-md font-bold">
        {driverListMap[filter]?.title ?? driverListMap['default']?.title}
      </h1>

      <div>{driverListMap[filter]?.list ?? driverListMap['default']?.list}</div>
    </div>
  );
};

export default DriversPage;
