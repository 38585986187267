import { useMutation } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { useContext, useMemo } from 'react';
import { Column, usePagination, useTable } from 'react-table';

import Button from 'atoms/Button';
import ButtonWithConfirmModal from 'atoms/ButtonWithConfirmModal';
import IonIcon from 'atoms/IonIcon';
import { LicencePlate } from 'atoms/LicencePlate';
import Pagination from 'atoms/Pagination';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import UpdateFrequencyForm from 'components/Reporting/UpdateFrequencyForm';
import useSettings from 'components/Settings/useSettings';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';
import {
  DeleteReportDoc,
  DistanceUnit,
  GetReportNotificationsDoc,
  GetReportsDoc,
  Report,
  ReportSchedule,
  UpdateReportDoc,
  VolumeUnit,
} from 'generated/graphql';
import { Result } from 'types';
import { cx } from 'utils';

const ordinal = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

interface ReportTableProps {
  data: Result<typeof GetReportsDoc> | Result<typeof GetReportNotificationsDoc>; // TODO: type this to the return type of the query
  isNotifications: boolean;
  editMode: boolean;
}

const Header = (text: string) => <div className={cx('text-left text-md')}>{text}</div>;

const useColumns = (isEditMode: boolean, isNotification: boolean) => {
  const i18nContext = useContext(I18nContext);
  const accessibleFleetMap = useAccessibleFleetMap();
  const [deleteReportMutation] = useMutation(DeleteReportDoc);
  const [updateReportMutation] = useMutation(UpdateReportDoc);
  const {
    currencyUnit,
    dieselPricePerLitre,
    idleTimeAsPercentage,
    distanceInMiles,
    petrolPricePerLitre,
    volumeInGallons,
  } = useSettings();

  const memoisedColumns = useMemo(() => {
    const deleteReport = (id: string) =>
      deleteReportMutation({
        variables: { id },
        update: (cache, { data }) => {
          if (!data) return;

          cache.evict({ id: cache.identify(data.deleteReport) });
          cache.gc();
        },
      });

    const updateReport = (reportId: string, report: Omit<Report, 'driver' | 'vehicle' | 'createdAt'>) => {
      updateReportMutation({
        variables: {
          id: reportId,
          report: {
            fleetIds: report.fleetIds,
            type: report.type,
            schedule: report.schedule,
            dayOfMonth: report.dayOfMonth,
            dayOfWeek: report.dayOfWeek,
            dateFrom: report.dateFrom,
            dateRange: report.dateRange,
            dateTo: report.dateTo,
          },
        },
      });
    };

    if (i18nContext) {
      const {
        commonTranslations: {
          enums: {
            reportPeriodDescriptionMap,
            reportScheduleDescriptionMap,
            reportTypeDescriptionMap,
            daysOfWeekDescriptionMap,
          },
        },
      } = i18nContext;

      const columns: Column<ReportTableProps['data'][0]>[] = [
        {
          accessor: 'type',
          Header: Header('Report Type'),
          Cell: ({ value }) => reportTypeDescriptionMap[value] ?? null,
          width: 10,
        },
        {
          accessor: 'fleetIds',
          Header: Header('Fleet'),
          Cell: ({ value }) => accessibleFleetMap[value[0] ?? '']?.name ?? null,
          width: 12,
        },
        {
          accessor: 'dateRange',
          Header: Header('Date Range'),
          Cell: ({ row, value }) => {
            return !value ? (
              <span>{`${row.original.dateFrom} to ${row.original.dateTo}`}</span>
            ) : (
              <span>{reportPeriodDescriptionMap[value ?? 'custom']}</span>
            );
          },
          width: 12,
        },
        {
          accessor: 'driver',
          Header: Header('Driver'),
          Cell: ({ value }) => value?.user.name ?? null,
          width: 10,
        },
        {
          accessor: 'vehicle',
          Header: Header('Vehicle'),
          Cell: ({ value }) =>
            value ? <LicencePlate licenceNumber={value?.vehicle.licencePlate} textClass="text-sm" /> : null,
          width: 8,
        },
        {
          accessor: 'schedule',
          Header: <div className="text-left text-md">Frequency</div>,
          Cell: ({ row, value }) => {
            if (isEditMode && !isNotification) {
              return <UpdateFrequencyForm reportId={row.original.id} initialState={row.original} />;
            }

            if (value === ReportSchedule.Once) {
              return reportScheduleDescriptionMap[value];
            }

            return (
              <>
                {`${reportScheduleDescriptionMap[value]} ${
                  value === ReportSchedule.Weekly && row.original.dayOfWeek
                    ? `on ${daysOfWeekDescriptionMap[row.original.dayOfWeek]}`
                    : `on the ${row.original.dayOfMonth ? ordinal(row.original.dayOfMonth) : ''}`
                }`}
              </>
            );
          },
          width: 15,
        },
        {
          accessor: 'createdAt',
          Header: Header('Created'),
          Cell: ({ value }) => format(parseISO(value), 'dd/MM/yyyy hh:mm:ss a'),
          width: 15,
        },
        {
          accessor: 'reportSentAt',
          Header: Header('Last Run'),
          Cell: ({ value }) => (value ? format(parseISO(value), 'dd/MM/yyyy hh:mm:ss a') : 'In Progress'),
          width: 12,
        },
        {
          accessor: 'id',
          Cell: ({ row }) => {
            if (isNotification) return null;

            const settingsIdleTime = idleTimeAsPercentage ? '%' : 'h';
            const settingsDistanceUnit = distanceInMiles ? DistanceUnit.Miles : DistanceUnit.Kilometres;
            const settingsVolumeUnit = volumeInGallons ? VolumeUnit.Gallon : VolumeUnit.Litre;
            const settingsChanged =
              currencyUnit !== row.original.currencyUnit ||
              dieselPricePerLitre !== row.original.dieselPrice ||
              petrolPricePerLitre !== row.original.petrolPrice ||
              settingsIdleTime !== row.original.idleTime ||
              settingsDistanceUnit !== row.original.distanceUnit ||
              settingsVolumeUnit !== row.original.volumeUnit;

            if (isEditMode) {
              return (
                <div className="text-right mr-1">
                  {settingsChanged && (
                    <Button
                      aria-label="Re-sync report"
                      title="Re-sync report"
                      onClick={() => updateReport(row.original.id, row.original)}
                    >
                      <IonIcon name="syncOutline" />
                    </Button>
                  )}

                  <ButtonWithConfirmModal
                    className="ml-1"
                    confirmTitle="Confirm Delete Report"
                    confirmContent="Are you sure you would like to delete this report?"
                    onConfirm={() => deleteReport(row.original.id)}
                  >
                    <IonIcon name="trashOutline" />
                  </ButtonWithConfirmModal>
                </div>
              );
            }

            if (settingsChanged) {
              return (
                <div className="text-right mr-1">
                  <Tooltip text="This report is out of sync with your settings!">
                    <IonIcon name="warningOutline" className="bg-yellow" />
                  </Tooltip>
                </div>
              );
            }

            return null;
          },
          width: 10,
        },
      ];
      return columns;
    } else {
      return [];
    }
  }, [
    accessibleFleetMap,
    currencyUnit,
    deleteReportMutation,
    dieselPricePerLitre,
    distanceInMiles,
    idleTimeAsPercentage,
    isEditMode,
    isNotification,
    petrolPricePerLitre,
    volumeInGallons,
    updateReportMutation,
    i18nContext,
  ]);

  return memoisedColumns;
};

const ReportTable = ({ data, isNotifications, editMode }: ReportTableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, pageCount, gotoPage } = useTable(
    {
      columns: useColumns(editMode, isNotifications),
      data: data,
      initialState: {
        pageSize: 6,
      },
    },
    usePagination,
  );

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((group) => (
          <tr {...group.getHeaderGroupProps()} className="border-b-px border-gray-300">
            {group.headers.map((column) => (
              <th {...column.getHeaderProps()} className={cx('px-1 py-1 font-bold')}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()} className="odd:bg-gray-300/30 text-md">
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} className={cx('px-1 py-1')} width={`${cell.column.width}%`}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>

      <tfoot>
        <tr className="border-t-px border-gray-300 ">
          <td colSpan={9}>
            <div className="flex w-full justify-end">
              <Pagination onChange={gotoPage} pageCount={pageCount} showCounter />
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default ReportTable;
