import React, { useContext, useEffect, useRef } from 'react';
import { Row, TableInstance } from 'react-table';

import { ConditionalWrapper } from 'atoms/ConditionalWrapper';
import IonIcon from 'atoms/IonIcon';
import { CenteredSpinner } from 'atoms/Spinner';
import Tooltip from 'atoms/Tooltip';
import { useT } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { userSortableTextFields } from 'components/User/UserList/useUserListColumns';
import { DtcEventType, ListSortDirection, TripStatisticType } from 'generated/graphql';
import { createTableContext, cx } from 'utils';

import PageSelector from './PageSelector';

export const stringToSortDirection = (direction: string): ListSortDirection => {
  if (direction === 'desc') return ListSortDirection.Desc;

  return ListSortDirection.Asc;
};

export const sortDirectionToString = (direction: ListSortDirection): string => {
  if (direction === ListSortDirection.Desc) return 'desc';

  return 'asc';
};

const FullPageTableContext = createTableContext<any>();
export const useFullPageTableContext = () => useContext(FullPageTableContext);

interface FullPageTableProps<T extends object> {
  table: TableInstance<T>;
  totalCount?: number;
  currentPage?: number;
  onPageChange?: (selected: number) => void;
  toggleSortBy?: (id?: string, direction?: string | null) => void;
  sortedColumn?: { field: string; direction?: string } | null;
  hiddenColumns: string[];
  isInEditMode?: boolean;
  useTablePagination?: boolean;
  loading?: boolean;
}

const FullPageTable = <T extends object>({
  table,
  totalCount,
  currentPage,
  onPageChange,
  toggleSortBy,
  sortedColumn,
  hiddenColumns,
  isInEditMode = false,
  useTablePagination = false,
  loading = false,
}: FullPageTableProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, setHiddenColumns } = table;
  const listRef = useRef<HTMLDivElement>(null);
  const { distanceInMiles, volumeInGallons, idleTimeAsPercentage } = useSettings();

  const toggleSortByDirection = (columnId: string) => {
    if (isInEditMode || !toggleSortBy) return;

    const newDirection =
      sortedColumn?.field === columnId
        ? sortedColumn.direction === sortDirectionToString(ListSortDirection.Asc)
          ? sortDirectionToString(ListSortDirection.Desc)
          : !sortedColumn.direction
          ? sortDirectionToString(ListSortDirection.Asc)
          : null
        : sortDirectionToString(ListSortDirection.Asc);

    toggleSortBy(columnId, newDirection);
  };

  const {
    tSafe,
    commonTranslations: {
      general: { sort_by_text },
      enums: { dtcEventTypeDescriptionMap, tripStatisticTypeDescriptionMap },
      domain: {
        fleet: { fleet_text },
        impact: { impact_events_text },
        vehicle: {
          fields: { licencePlate_text, make_text, model_text },
        },
        vehicleDetails: {
          fields: {
            mil_status_text,
            activeDashboardLights_text,
            battery_voltage_text,
            battery_health_text,
            batteryStatus_text,
          },
        },
        driver: { driver_text, longIdling_text },
        device: {
          fields: { device_connection_status_text },
        },
        user: {
          fields: { active_text, email_text, name_text, role_text },
        },
      },
    },
  } = useT();

  const sortFieldTextMap: Record<string, string> = {
    active: active_text,
    batteryHealth: battery_health_text,
    batteryCharge: battery_voltage_text,
    batteryStatus: batteryStatus_text,
    dashboardLights: activeDashboardLights_text,
    deviceStatus: device_connection_status_text,
    distanceDriven: tripStatisticTypeDescriptionMap[TripStatisticType.TotalDistanceDriven],
    dtcPending: dtcEventTypeDescriptionMap[DtcEventType.Pending],
    dtcPermanent: dtcEventTypeDescriptionMap[DtcEventType.Permanent],
    dtcStored: dtcEventTypeDescriptionMap[DtcEventType.Stored],
    drivingTime: tripStatisticTypeDescriptionMap[TripStatisticType.TotalTimeDriven],
    driver: driver_text,
    email: email_text,
    ecoScore: tripStatisticTypeDescriptionMap[TripStatisticType.EcoScore],
    fleet: fleet_text,
    fuel: tripStatisticTypeDescriptionMap[TripStatisticType.FuelConsumption],
    fuelConsumption: tripStatisticTypeDescriptionMap[TripStatisticType.FuelConsumption],
    fuelEfficiency: tripStatisticTypeDescriptionMap[TripStatisticType.FuelEfficiency],
    idleTime: idleTimeAsPercentage
      ? tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimePct]
      : tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimeHrs],
    longIdlingEventCount: longIdling_text,
    impactStatus: impact_events_text,
    licencePlate: licencePlate_text,
    make: make_text,
    model: model_text,
    milStatus: mil_status_text,
    name: name_text,
    role: role_text,
    tripCount: tripStatisticTypeDescriptionMap[TripStatisticType.TripCount],
  };

  useEffect(() => {
    isInEditMode ? setHiddenColumns([]) : setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, isInEditMode, setHiddenColumns]);

  const Headers = headerGroups.map((group) => (
    <thead
      key={group.getHeaderGroupProps().key}
      className="sticky top-0 bg-gray-100 pb-0.5 shadow-[0_4px_6px_-4px_rgba(0,0,0,0.2)] z-500"
    >
      <tr>
        {group.headers.map((column, index) => (
          <th
            className={cx('align-bottom text-sm font-normal', hiddenColumns.includes(column.id) && 'opacity-40')}
            key={`${column}-${index}`}
          >
            <div className={cx('flex py-0.5 w-full min-w-16')} style={{ width: column.width }}>
              <ConditionalWrapper
                condition={column.canSort}
                wrapper={({ children }: { children: JSX.Element }) => (
                  <Tooltip text={`${sort_by_text} ${sortFieldTextMap[column.id]}`}>{children}</Tooltip>
                )}
              >
                <span
                  className={cx(
                    column.canSort && !isInEditMode && 'cursor-pointer',
                    column.id === sortedColumn?.field && sortedColumn.direction != null && 'font-bold',
                    column.canSort && 'hover:font-bold',
                    'flex text-left pl-[3px]',
                  )}
                  onClick={() => {
                    if (column.canSort) {
                      toggleSortByDirection(column.id);
                      if (userSortableTextFields.includes(column.id)) {
                        column.toggleSortBy();
                      }
                    }
                  }}
                >
                  {column.render('Header')}

                  {sortedColumn?.field !== column.id ? null : (
                    <div className="inline ml-0.5 pt-[1px] text-[10px]">
                      {sortedColumn.direction === sortDirectionToString(ListSortDirection.Desc) ? (
                        <IonIcon name="arrowDown" className="text-md" />
                      ) : sortedColumn.direction === sortDirectionToString(ListSortDirection.Asc) ? (
                        <IonIcon name="arrowUp" className="text-md" />
                      ) : null}
                    </div>
                  )}
                </span>
              </ConditionalWrapper>
            </div>

            <div className="h-3 mb-[2px]">
              {!column.Filter ? null : <div className="w-full h-4 ">{column.render('Filter')}</div>}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  ));

  const rows = (rowGroup: Row<T>[]) =>
    rowGroup.map((row, index) => {
      prepareRow(row);

      return (
        <tr className={cx(index % 2 && 'bg-white', 'bg-gray-100 my-1 hover:brightness-90')} key={`${row.id}-${index}`}>
          {row.cells.map((cell) => (
            <td
              className={cx('p-1 items-center text-sm', hiddenColumns.includes(cell.column.id) && 'opacity-40')}
              key={`${cell.column.id}-${index}`}
            >
              {cell.render('Cell')}
            </td>
          ))}
        </tr>
      );
    });

  return (
    <FullPageTableContext.Provider value={table}>
      <div className="h-full mx-auto pt-0.5">
        <div ref={listRef} className="h-[calc(100vh_-_160px)] w-[calc(100vw_-_20px)] overflow-x-auto overflow-y-auto">
          <table {...getTableProps()} className="mx-auto">
            {Headers}

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td>
                    <CenteredSpinner className="absolute top-1/2 left-1/2" />
                  </td>
                </tr>
              ) : table.rows.length ? (
                rows(useTablePagination ? page : table.rows)
              ) : (
                <tr>
                  <td className="absolute left-0 top-0 flex-center w-full h-full pointer-events-none items-center">
                    {tSafe('components.Vehicle.VehicleList.no-results', {
                      defaultValue: 'No results found for the selected filters',
                    })}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <PageSelector
            className={cx(totalCount && totalCount < 20 && 'opacity-0')}
            currentPage={currentPage}
            totalCount={totalCount}
            onPageChange={onPageChange}
            useTablePagination
          />
        </div>
      </div>
    </FullPageTableContext.Provider>
  );
};

export default FullPageTable;
