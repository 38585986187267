import { PropsWithChildren, useContext, useMemo } from 'react';
import { CellProps } from 'react-table';
import { useRecoilValue } from 'recoil';

import ColumnCollection from 'atoms/react-table/ColumnCollection';
import fuzzySearchFilter from 'atoms/react-table/tableFilters/fuzzySearchFilter';
import multiSelectFilter from 'atoms/react-table/tableFilters/multiSelectFilter';
import rangeFilter from 'atoms/react-table/tableFilters/rangeFilter';
import { tripStatisticConversionRatios } from 'common/uomConverters';
import { I18nContext } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { vehicleListState } from 'components/Vehicle/state';
import BatteryHealthCell from 'components/Vehicle/VehicleList/cells/BatteryHealthCell';
import BatteryStatusCell from 'components/Vehicle/VehicleList/cells/BatteryStatusCell';
import DeviceStatusCell from 'components/Vehicle/VehicleList/cells/DeviceStatusCell';
import LicencePlateCell from 'components/Vehicle/VehicleList/cells/LicencePlateCell';
import MilStatusCell from 'components/Vehicle/VehicleList/cells/MilStatusCell';
import VehicleListDashboardLights from 'components/Vehicle/VehicleList/VehicleListDashboardLights';
import VehicleListDriverName from 'components/Vehicle/VehicleList/VehicleListDriverCell';
import VehicleListDtc from 'components/Vehicle/VehicleList/VehicleListDtcs';
import VehicleListHeader from 'components/Vehicle/VehicleList/VehicleListHeader';
import {
  BatteryNotificationType,
  DashboardLightMessageType,
  DeviceConnectionStatus,
  DtcEventType,
  GetVehicleListQuery,
  TripStatisticType,
} from 'generated/graphql';
import { capitalize, entries } from 'utils';

export type VehicleListItem = GetVehicleListQuery['aggregatedVehicleDetails']['data'][number];

export type VehicleListCell<T> = PropsWithChildren<CellProps<VehicleListItem, T>>;

export type VehicleListColumnId =
  | 'licencePlate'
  | 'make'
  | 'model'
  | 'driver'
  | 'deviceStatus'
  | 'milStatus'
  | 'dashboardLights'
  | 'batteryCharge'
  | 'batteryHealth'
  | 'batteryStatus'
  | 'dtcPending'
  | 'dtcStored'
  | 'dtcPermanent'
  | 'distanceDriven'
  | 'fuel'
  | 'unassigned'
  | 'impactStatus';

export const vehicleListUniqueValueColumns: [VehicleListColumnId, (x: VehicleListItem) => string | null | undefined][] =
  [
    ['make', (x) => x.vehicle.make],
    ['model', (x) => x.vehicle.model],
    ['driver', (x) => x.associations.driver?.name],
  ];

export const vehicleListMinMaxValueColumns: [VehicleListColumnId, (x: VehicleListItem) => number | null | undefined][] =
  [['distanceDriven', (x) => x.tripStatistics.totalDistanceDriven ?? 0]];

export const useVehicleListColumns = () => {
  const i18nContext = useContext(I18nContext);

  const { distanceInMiles, volumeInGallons } = useSettings();
  const conversionRatioMap = tripStatisticConversionRatios({ distanceInMiles, volumeInGallons });
  const { uniqueValues, minMaxValues } = useRecoilValue(vehicleListState);
  const { make, model } = uniqueValues;
  const dedupedSortedMakes = Array.from(new Set(make?.map((x) => x.toUpperCase()))).sort();
  const dedupedSortedModels = Array.from(new Set(model?.map((x) => x.toUpperCase()))).sort();

  const columns = useMemo(() => {
    if (i18nContext) {
      const {
        commonTranslations: {
          general: { some_text, none_text, on_text, off_text, unknown_text, unassigned_text },
          enums: {
            batterySohStateDescriptionMap,
            batteryNotificationTypeDescriptionMap,
            dashboardLightMessageTypeDescriptionMap,
            deviceConnectionStatusDescriptionMap,
            dtcEventTypeDescriptionMap,
            tripStatisticTypeDescriptionMap,
          },
          domain: {
            vehicle: {
              fields: { licencePlate_text, make_text, model_text },
            },
            vehicleDetails: {
              fields: {
                mil_status_text,
                activeDashboardLights_text,
                battery_voltage_text,
                battery_health_text,
                batteryStatus_text,
              },
            },
            driver: { driver_text },
            device: {
              fields: { device_connection_status_text },
            },
          },
        },
      } = i18nContext;

      const collection = new ColumnCollection<VehicleListItem, VehicleListColumnId>(VehicleListHeader);

      collection.add('licencePlate', licencePlate_text, (x) => x.vehicle.licencePlate, {
        containerClassName: 'w-full',
        component: LicencePlateCell,
        filter: fuzzySearchFilter(),
        width: 120,
      });

      collection.add('make', make_text, (x) => x.vehicle.make.toUpperCase(), {
        containerClassName: 'capitalize w-full',
        filter: multiSelectFilter(
          dedupedSortedMakes.map((value) => ({
            label: value.toUpperCase(),
            value: value.toUpperCase(),
          })) ?? [],
          { searchable: true },
        ),
        width: 140,
      });

      collection.add('model', model_text, (x) => x.vehicle.model?.toLowerCase(), {
        containerClassName: 'capitalize w-full',
        filter: multiSelectFilter(
          dedupedSortedModels.map((value) => ({ label: capitalize(String(value).toLocaleLowerCase()), value })) ?? [],
          { searchable: true },
        ),
        width: 130,
      });

      collection.add('driver', driver_text, (x) => x.associations.driver?.name ?? unknown_text, {
        containerClassName: 'capitalize w-full',
        component: VehicleListDriverName,
        filter: fuzzySearchFilter(),
      });

      collection.add(
        'deviceStatus',
        device_connection_status_text,
        ({ associations: { device } }) => device?.connectionStatus ?? DeviceConnectionStatus.Disconnected,
        {
          component: DeviceStatusCell,
          containerClassName: 'w-full flex-center',
          sortType: (a, b) =>
            new Date(a.original.associations.device?.latestConnectionDate ?? 0).getTime() -
            new Date(b.original.associations.device?.latestConnectionDate ?? 0).getTime(),
          sortInverted: true,

          filter: multiSelectFilter(
            entries(deviceConnectionStatusDescriptionMap).map(([type, description]) => ({
              label: description,
              value: type,
            })),
          ),
          width: 140,
        },
      );

      collection.add('milStatus', mil_status_text, (x) => x.status.mil, {
        component: MilStatusCell,
        containerClassName: 'w-full flex-center',
        filter: multiSelectFilter([
          { label: on_text, value: 'true' },
          { label: off_text, value: 'false' },
        ]),
        width: 140,
      });

      collection.add('dashboardLights', activeDashboardLights_text, (x) => x.activeDashboardLights.map((x) => x.type), {
        component: VehicleListDashboardLights,
        containerClassName: 'w-full flex-center',
        sortType: (a, b) =>
          a.original.activeDashboardLights.length > b.original.activeDashboardLights.length ? 1 : -1,
        filter: multiSelectFilter(
          entries(dashboardLightMessageTypeDescriptionMap)
            .filter(([type]) => type !== DashboardLightMessageType.EngineMilStatus)
            .map(([type, label]) => ({
              label,
              value: type,
            })),
        ),
        width: 170,
      });

      collection.add(
        'batteryCharge',
        battery_voltage_text,
        (x) => x.batteryStatus.currentState?.lastVoltage?.toFixed(2),
        {
          containerClassName: 'w-full flex-center',
          width: 160,
        },
      );

      collection.add('batteryHealth', battery_health_text, (x) => x.batteryStatus.stateOfHealth?.state, {
        component: BatteryHealthCell,
        containerClassName: 'w-full flex-center',
        filter: multiSelectFilter(
          entries(batterySohStateDescriptionMap).map(([type, label]) => ({
            label: label,
            value: type,
          })),
        ),
        width: 140,
      });

      collection.add('batteryStatus', batteryStatus_text, (x) => x.status.batteryStatus, {
        component: BatteryStatusCell,
        containerClassName: 'w-full flex-center',
        filter: multiSelectFilter(
          entries(batteryNotificationTypeDescriptionMap)
            .filter(([type, _]) => type !== BatteryNotificationType.Drain)
            .map(([type, label]) => ({ label, value: type })),
        ),
        width: 140,
      });

      const dtcFilter = multiSelectFilter([
        { label: some_text, value: 'true' },
        { label: none_text, value: 'false' },
      ]);

      collection.add(
        'dtcPending',
        dtcEventTypeDescriptionMap[DtcEventType.Pending],
        (x) => (x.activeDtcs.filter((dtc) => dtc.dtcType === DtcEventType.Pending).length ? true : false),
        {
          component: ({ row }) => <VehicleListDtc dtcs={row.original.activeDtcs} dtcType={DtcEventType.Pending} />,
          containerClassName: 'w-full flex-center',
          sortType: 'basic',
          filter: dtcFilter,
          width: 140,
        },
      );

      collection.add(
        'dtcStored',
        dtcEventTypeDescriptionMap[DtcEventType.Stored],
        (x) => (x.activeDtcs.filter((dtc) => dtc.dtcType === DtcEventType.Stored).length ? true : false),
        {
          component: ({ row }) => <VehicleListDtc dtcs={row.original.activeDtcs} dtcType={DtcEventType.Stored} />,
          containerClassName: 'w-full flex-center',
          sortType: 'basic',
          filter: dtcFilter,
          width: 140,
        },
      );

      collection.add(
        'dtcPermanent',
        dtcEventTypeDescriptionMap[DtcEventType.Permanent],
        (x) => (x.activeDtcs.filter((dtc) => dtc.dtcType === DtcEventType.Permanent).length ? true : false),
        {
          component: ({ row }) => <VehicleListDtc dtcs={row.original.activeDtcs} dtcType={DtcEventType.Permanent} />,
          containerClassName: 'w-full flex-center',
          sortType: 'basic',
          filter: dtcFilter,
          width: 140,
        },
      );

      collection.add(
        'distanceDriven',
        tripStatisticTypeDescriptionMap[TripStatisticType.TotalDistanceDriven],
        ({ tripStatistics: { totalDistanceDriven } }) =>
          (totalDistanceDriven
            ? totalDistanceDriven * conversionRatioMap[TripStatisticType.TotalDistanceDriven]
            : undefined
          )?.toFixed(0) ?? 0,
        {
          containerClassName: 'w-full flex-center',
          filter: rangeFilter({ min: 0, max: 1200000 }),
          width: 180,
        },
      );

      collection.add(
        'fuel',
        tripStatisticTypeDescriptionMap[TripStatisticType.FuelConsumption],
        ({ tripStatistics: { fuelConsumption } }) =>
          (fuelConsumption
            ? fuelConsumption * conversionRatioMap[TripStatisticType.FuelConsumption]
            : undefined
          )?.toFixed(0) ?? 0,
        {
          containerClassName: 'w-full flex-center',
          width: 150,
        },
      );

      collection.add('unassigned', unassigned_text, (_) => null, {
        containerClassName: 'w-full flex-center',
        width: 160,
        disableSortBy: true,
        filter: multiSelectFilter([{ label: 'No Driver', value: 'Driver' }]),
      });

      return collection.toArray();
    } else {
      return [];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    distanceInMiles,
    minMaxValues.distanceDriven,
    uniqueValues.make,
    uniqueValues.model,
    volumeInGallons,
    i18nContext,
  ]);

  return columns;
};
