import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { UserListCell } from 'components/User/UserList/useUserListColumns';

const UserListDriverCell = ({ row }: UserListCell<boolean>) => {
  const i18nContext = useContext(I18nContext);
  const currentFleetId = useCurrentFleetId();

  if (!row.original.driverId || !i18nContext) {
    return null;
  }

  const {
    commonTranslations: {
      domain: {
        driver: { driver_text },
      },
    },
  } = i18nContext;

  return (
    <Link to={`/${currentFleetId}/drivers/${row.original.driverId}`} className="text-hover hover:underline">
      {driver_text}
    </Link>
  );
};

export default UserListDriverCell;
