import { useMutation } from '@apollo/client';
import { PropsWithChildren } from 'react';
import { HeaderProps } from 'react-table';
import { useRecoilValue } from 'recoil';

import TableHeader from 'atoms/react-table/TableHeader';
import useSettings from 'components/Settings/useSettings';
import { userListState } from 'components/User/UserList/state';
import { SetUserListHiddenColumnsDoc } from 'generated/graphql';

interface UserListHeaderProps extends PropsWithChildren<HeaderProps<object>> {
  text: string;
}

const UserListHeader = ({ text, ...props }: UserListHeaderProps) => {
  const { isInEditMode } = useRecoilValue(userListState);
  const { hiddenColumns } = useSettings().userListSettings;
  const [setHiddenItems] = useMutation(SetUserListHiddenColumnsDoc);

  const onChange = (hiddenColumns: string[]) => setHiddenItems({ variables: { hiddenColumns } });

  return (
    <TableHeader
      {...props}
      text={text}
      alignment={'left'}
      isEditMode={isInEditMode}
      hiddenColumns={hiddenColumns}
      onChange={onChange}
    />
  );
};

export default UserListHeader;
