import { PropsWithChildren, useContext, useMemo } from 'react';
import { CellProps, Row } from 'react-table';
import { useRecoilValue } from 'recoil';

import ColumnCollection from 'atoms/react-table/ColumnCollection';
import fuzzySearchFilter from 'atoms/react-table/tableFilters/fuzzySearchFilter';
import multiSelectFilter from 'atoms/react-table/tableFilters/multiSelectFilter';
import { I18nContext } from 'common/useT';
import { userListState } from 'components/User/UserList/state';
import UserListActionsCell from 'components/User/UserList/UserListActionsCell';
import UserListActiveCell from 'components/User/UserList/UserListActiveCell';
import UserListDriverCell from 'components/User/UserList/UserListDriverCell';
import UserListFleetCell from 'components/User/UserList/UserListFleetsCell';
import UserListHeader from 'components/User/UserList/UserListHeader';
import UserListRoleCell from 'components/User/UserList/UserListRoleCell';
import useRole from 'components/User/useRole';
import { GetUsersDoc } from 'generated/graphql';
import { Result } from 'types';
import { capitalize } from 'utils';

import useAccessibleFleetMap from '../useAccessibleFleetMap';

export type UserListItem = Result<typeof GetUsersDoc>[number];

export type UserListCell<T> = PropsWithChildren<CellProps<UserListItem, T>>;

export type UserListColumnId = 'name' | 'role' | 'driver' | 'email' | 'fleets' | 'actions' | 'active';

export const userSortableTextFields = ['name', 'role', 'driver', 'email', 'fleets', 'active'];
export const userListUniqueValueColumns: [UserListColumnId, (x: UserListItem) => string | null | undefined][] = [
  ['role', (x) => x.role],
];

export const useUserListColumns = () => {
  const i18nContext = useContext(I18nContext);
  const { uniqueValues } = useRecoilValue(userListState);
  const { isManager } = useRole();
  const accessibleFleetMap = useAccessibleFleetMap();

  const insensitiveCompare = (rowA: Row<any>, rowB: Row<any>, id: string) =>
    rowA.values[id]?.toLowerCase().localeCompare(rowB.values[id]?.toLowerCase());

  const columns = useMemo(() => {
    if (i18nContext) {
      const {
        tSafe,
        commonTranslations: {
          general: { yes_text, no_text, inactive_text },
          domain: {
            user: {
              fields: { name_text, role_text, active_text, email_text },
            },
            fleet: { fleets_text },
            driver: { driver_text },
          },
        },
      } = i18nContext;
      const collection = new ColumnCollection<UserListItem, UserListColumnId>(UserListHeader);

      collection.add('name', name_text, (x) => x.name, {
        filter: fuzzySearchFilter(),
        width: 240,
        sortType: insensitiveCompare,
      });

      collection.add('role', role_text, (x) => (x.role ? String(x.role) : ''), {
        containerClassName: 'capitalize',
        filter: multiSelectFilter(
          [
            {
              label: tSafe('components.User.UserList.useUserListColumns.no-role', { defaultValue: 'No Role' }),
              value: null,
            },
            ...(uniqueValues.role?.map((value) => ({ label: capitalize(String(value).toLocaleLowerCase()), value })) ??
              []),
          ],
          {
            useTableFiltering: true,
          },
        ),
        component: UserListRoleCell,
        sortType: insensitiveCompare,
      });

      collection.add('driver', driver_text, (x) => !!x.driverId, {
        containerClassName: 'capitalize',
        filter: multiSelectFilter(
          [
            { label: yes_text, value: 'yes' },
            { label: no_text, value: 'no' },
          ],
          {
            useTableFiltering: true,
          },
        ),
        component: UserListDriverCell,
        sortType: 'basic',
      });

      collection.add('active', active_text, (x) => x.active, {
        filter: multiSelectFilter(
          [
            { label: active_text, value: 'active' },
            { label: inactive_text, value: 'inactive' },
          ],
          { useTableFiltering: true },
        ),
        sortType: 'basic',
        component: UserListActiveCell,
        containerClassName: 'w-full flex-center',
        width: 130,
      });

      collection.add('email', email_text, (x) => x.email, {
        filter: fuzzySearchFilter(),
        width: 300,
        sortType: insensitiveCompare,
      });

      collection.add('fleets', fleets_text, (x) => x.fleets.map((fleet) => accessibleFleetMap[fleet.id]?.name), {
        component: UserListFleetCell,
        disableSortBy: true,
        filter: fuzzySearchFilter(),
        containerClassName: 'flex flex-col w-full h-full overflow-y-scroll',
        width: 200,
      });

      if (isManager) {
        collection.add('actions', 'Actions', (x) => x, {
          component: UserListActionsCell,
          containerClassName: 'w-full flex-center',
          disableSortBy: true,
          width: 90,
        });
      }

      return collection.toArray();
    } else {
      return [];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessibleFleetMap, isManager, uniqueValues.role, i18nContext]);

  return columns;
};
