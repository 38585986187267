import { useContext } from 'react';
import { Marker, TileLayer } from 'react-leaflet';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';

import { VehicleMarker } from 'atoms/LeafletMapIcons/VehicleMarker';
import { defaultDashboardTimePeriod } from 'common/constants';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { InitialCoordsState } from 'components/Live';
import { selectedVehicleState } from 'components/Live/state';
import { GetVehicleDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

interface MapProps {
  vehicleId: string;
}

export const Map = ({ vehicleId }: MapProps) => {
  const i18nContext = useContext(I18nContext);
  const currentFleetId = useCurrentFleetId();
  const setLiveMapSelectedVehicle = useSetRecoilState(selectedVehicleState);
  const history = useHistory<InitialCoordsState>();
  const {
    loading,
    error,
    data: vehicleDetails,
  } = useQ(GetVehicleDoc, {
    variables: {
      vehicleIds: vehicleId!,
      period: defaultDashboardTimePeriod,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading || !vehicleDetails || !i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { unavailable_text },
      errors: { error_text },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  const [
    {
      status: { time },
      lastKnownLocations,
      vehicle: { make, model, licencePlate },
      trips,
      associations: { driver, fleetIds },
    },
  ] = vehicleDetails;

  const [{ lat, lng }] = lastKnownLocations;
  const [lastTrip] = trips;
  const driverName = driver ? driver.user.name ?? `${make} ${model}` : `${make} ${model}`;

  const redirectToLiveMap = () => {
    if (lat && lng) {
      setLiveMapSelectedVehicle({
        driverId: driver?.id,
        driverName: driver?.user.name,
        licencePlate: licencePlate,
        tripEnded: lastTrip?.tripEnded ?? true,
        vehicleId: vehicleId!,
        lastKnownLocation: { lat, lng, time: time ?? unavailable_text },
        fleetIds,
        make,
        model,
      });
    }

    history.push(`/${currentFleetId}/live`, lat && lng ? { location: { lat, lng }, zoom: 16 } : undefined);
  };

  return (
    <>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" zIndex={0} />

      {lat && lng ? (
        <Marker
          position={[lat, lng]}
          icon={VehicleMarker({
            driverName,
            licencePlate:
              licencePlate ??
              tSafe('components.Driver.Detail.LastKnownLocation.Map.marker.no-licence-plate', {
                defaultValue: 'No licence plate',
              }),
            tripEnded: lastTrip?.tripEnded ?? true,
            isSelected: false,
          })}
          eventHandlers={{
            click: () => redirectToLiveMap(),
          }}
        />
      ) : null}
    </>
  );
};
