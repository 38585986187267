import useRole from 'components/User/useRole';
import AddVehicleToolbarItem from 'components/Vehicle/VehicleList/VehicleListToolbar/AddVehicleToolbarItem';
import VehicleListEditModeToolbarItem from 'components/Vehicle/VehicleList/VehicleListToolbar/VehicleListEditModeToolbarItem';

const VehicleListToolbar = () => {
  const { isManager } = useRole();

  return (
    <>
      {isManager && <AddVehicleToolbarItem />}

      <VehicleListEditModeToolbarItem />
    </>
  );
};

export default VehicleListToolbar;
