import { format } from 'date-fns';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { InfoPanel } from 'atoms/InfoPanel';
import { InfoPanelItem } from 'atoms/InfoPanel/InfoPanelItem';
import { deviceDiagnosticIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import { DeviceDiagnosticMessage } from 'generated/graphql';
import { cx } from 'utils';

interface DeviceDiagnosticEventDetailProps {
  event: Partial<DeviceDiagnosticMessage>;
}

const DeviceDiagnosticEventDetail = ({ event }: DeviceDiagnosticEventDetailProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { time_text, unavailable_text },
      enums: { deviceDiagnosticDescriptionMap },
      domain: {
        deviceDiagnostic: {
          fields: { type_text },
        },
      },
    },
  } = i18nContext;

  const { type, time } = event;

  return (
    <div className="mt-2">
      <InfoPanel>
        <Icon name={deviceDiagnosticIconMap[type!][0]} className={cx('!w-4', deviceDiagnosticIconMap[type!][1])} />

        <InfoPanelItem description={type_text} value={deviceDiagnosticDescriptionMap[type!]} />

        <InfoPanelItem description={time_text} value={time ? format(Date.parse(time), 'PPPPp') : unavailable_text} />
      </InfoPanel>
    </div>
  );
};

export default DeviceDiagnosticEventDetail;
