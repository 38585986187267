import { useContext } from 'react';

import DeviceStatusIcon from 'atoms/DeviceStatusIcon';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { DriverListCell } from 'components/Driver/DriverList/useDriverListColumns';
import { DeviceConnectionStatus } from 'generated/graphql';

const DeviceStatusCell = ({ value: connectionStatus }: DriverListCell<DeviceConnectionStatus>) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { deviceConnectionStatusDescriptionMap },
    },
  } = i18nContext;

  return (
    <div className="w-full flex-center">
      <Tooltip
        text={
          connectionStatus === DeviceConnectionStatus.Connected
            ? deviceConnectionStatusDescriptionMap[DeviceConnectionStatus.Connected]
            : deviceConnectionStatusDescriptionMap[DeviceConnectionStatus.Disconnected]
        }
        side="right"
      >
        <DeviceStatusIcon connectionStatus={connectionStatus} />
      </Tooltip>
    </div>
  );
};

export default DeviceStatusCell;
