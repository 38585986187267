import { format } from 'date-fns';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { deviceDiagnosticIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import {
  DateTimeColumn,
  EventDetailContainer,
  EventTypeColumn,
  IconColumn,
} from 'components/ActivityHistoryList/styledComponents';
import { DeviceDiagnosticMessage } from 'generated/graphql';
import { cx } from 'utils';

interface DeviceDiagnosticEventRowItemProps {
  event: Partial<DeviceDiagnosticMessage>;
  time: Date;
}

const DeviceDiagnosticEventRowItem = ({ event, time }: DeviceDiagnosticEventRowItemProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { deviceDiagnosticDescriptionMap },
    },
  } = i18nContext;

  const { type } = event;

  return (
    <EventDetailContainer>
      <DateTimeColumn>{format(time, 'iii PPp')}</DateTimeColumn>

      <EventTypeColumn>{deviceDiagnosticDescriptionMap[type!]}</EventTypeColumn>

      <IconColumn>
        <Icon name={deviceDiagnosticIconMap[type!][0]} className={cx('!w-4', deviceDiagnosticIconMap[type!][1])} />
      </IconColumn>
    </EventDetailContainer>
  );
};

export default DeviceDiagnosticEventRowItem;
